import { initProfiling, getInteractionId } from '@fraudprevention/device-intelligence';

import Insight, { IInsightDependencies, FeatureFlags } from './Insight';
import { reportPageView, reportInvoicePayInCP } from './Reporting';

import Auth from 'businessLogic/Auth';
import CompanyInfo from 'businessLogic/CompanyInfo';
import Config from 'businessLogic/Config';
import ICPState from 'businessLogic/ICPState';
import WavefrontReporter from 'reporting/wavefront/WavefrontReporter';
import HttpClient from 'server/helpers/HttpClient';
import { getWeakTicket, mark } from 'shared/clientUtils';

const wavefrontReporter = WavefrontReporter.getInstance();

const { SplunkReporter } = require('reporting/splunk/SplunkReporter');
const splunkReporter = SplunkReporter.getInstance();
const logger = 'businessLogic/Insight/index';
const httpClient = HttpClient.getInstance();

type DeviceIntelligenceLogger = {
  info: (message: string, props: Record<string, any>) => void;
  warn: (message: string, props: Record<string, any>) => void;
  error: (message: string, props: Record<string, any>) => void;
  logException: (message: string, error: Error, props: Record<string, any>) => void;
};

const deviceIntelligenceEvent = 'risk';
const deviceIntelligenceAction = 'device-intelligence-library';

const deviceIntelligenceLogger: DeviceIntelligenceLogger = {
  info: (message, props) => {
    splunkReporter.contextual({
      logInfo: { logLevel: 'info', logger },
      event: deviceIntelligenceEvent,
      action: deviceIntelligenceAction,
      activityInfo: {
        message,
        ...props,
      },
    });
  },
  warn: (message, props) => {
    splunkReporter.contextual({
      logInfo: { logLevel: 'warn', logger },
      event: deviceIntelligenceEvent,
      action: deviceIntelligenceAction,
      activityInfo: {
        message,
        ...props,
      },
    });
  },
  error: (message, props) => {
    splunkReporter.contextual({
      logInfo: { logLevel: 'error', logger },
      event: deviceIntelligenceEvent,
      action: deviceIntelligenceAction,
      activityInfo: {
        message,
        ...props,
      },
    });
  },
  logException: (message, error, props) => {
    splunkReporter.contextual({
      logInfo: { logLevel: 'error', logger },
      event: deviceIntelligenceEvent,
      action: deviceIntelligenceAction,
      activityInfo: {
        message,
        ...props,
      },
      error: error,
    });
  },
};

export default class Index implements Insight {
  config: Config;
  auth: Auth;
  companyInfo: CompanyInfo;
  private _data: ICPState['insight'];
  biocatchSessionId?: string;

  constructor({ initialState, config, auth, companyInfo }: IInsightDependencies) {
    this.config = config;
    this.auth = auth;
    this.companyInfo = companyInfo;
    this._data = initialState.insight;
    this.setBiocatchSessionId();
  }

  private setBiocatchSessionId() {
    this.biocatchSessionId = this.config && this.config.bioCatchSessionId;
  }

  shouldGetRiskAssessment(): boolean {
    const { regionIsoCode } = this.auth;
    const biocatchExcludedStates = this.config && this.config.biocatchExcludedStates;
    const isExcluded = biocatchExcludedStates && biocatchExcludedStates.includes(regionIsoCode);
    const shouldGetAssessment = this.isPayable && !isExcluded;
    splunkReporter.contextual({
      logInfo: { logLevel: 'info', logger },
      event: 'risk',
      action: 'shouldGetRiskAssessment',
      activityInfo: {
        shouldGetAssessment,
        isPayable: this.isPayable,
        regionIsoCode,
        biocatchExcludedStates,
      },
    });
    return shouldGetAssessment;
  }

  initRiskJS = async ({
    pageName,
    featureFlags,
  }: {
    pageName: string;
    featureFlags: FeatureFlags;
  }) => {
    if (featureFlags['new-di-risk-library']) {
      return false;
    }
    let initRiskJSMark = mark('initRiskJS');
    const { browserApiKey, ssrtid } = this.config;
    const { realmId } = this.auth;
    //@ts-expect-error
    //Element implicitly has an any type because index expression is not of type number
    if (!window['mrjs'] || !browserApiKey || !realmId) {
      splunkReporter.contextual({
        logInfo: { logLevel: 'error', logger },
        event: 'risk',
        action: 'init',
        activityInfo: {
          status: 'error',
        },
        error: {
          message: `Missing params, realmId=${realmId}, browserApiKey=${browserApiKey}, typeof window['mrjs']=${typeof window[
            //@ts-ignore
            'mrjs'
          ]}`,
        },
      });
      return;
    }
    let riskJSInitParams = {
      companyId: realmId,
      apiKey: browserApiKey,
      pageName: pageName.replace('/', ''),
      intuitTid: `cp-c${ssrtid.slice(4)}`,
      transactionType: 'CP Invoice Payment',
    };

    //@ts-ignore
    return await window['mrjs']
      .init(['paymentsProcessing', 'bioCatch'], riskJSInitParams)
      .then((moneyRisk: any) => {
        //@ts-ignore
        window['mrjsInstance'] = moneyRisk;
        initRiskJSMark.finish();
        splunkReporter.contextual({
          logInfo: { logLevel: 'info', logger },
          event: 'risk',
          action: 'init',
          activityInfo: {
            status: 'success',
            riskIntuitTid: riskJSInitParams?.intuitTid,
          },
        });
        return moneyRisk;
      })
      .catch((error: any) => {
        splunkReporter.contextual({
          logInfo: { logLevel: 'error', logger },
          event: 'risk',
          action: 'init',
          activityInfo: {
            status: 'error',
            riskIntuitTid: riskJSInitParams?.intuitTid,
          },
          error: {
            message: error.message,
          },
        });
      });
  };
  private getRiskProfileTokenDiEnv: () => 'prod' | 'e2e' | 'qal' = () => {
    switch (this.config.env) {
      case 'prod':
      case 'stg':
        return 'prod';
      case 'qal':
        return 'qal';
      default:
        return 'e2e';
    }
  };
  private getRiskProfileTokenDi: (p: {
    pageName: string;
    companyId: string;
  }) => Promise<string | null> = ({ pageName, companyId }) =>
    new Promise((res, rej) => {
      const handleProfilingComplete = async () => {
        try {
          const interactionIdRes = await getInteractionId();
          this._data.riskProfileToken = interactionIdRes;
          splunkReporter.setClientSession({ riskProfileToken: this._data.riskProfileToken });
          splunkReporter.contextual({
            logInfo: { logLevel: 'info', logger },
            event: 'risk',
            action: 'getRiskToken',
            activityInfo: {
              status: 'success',
              riskProfileToken: this._data.riskProfileToken,
            },
          });
          res(interactionIdRes || null);
        } catch (e) {
          splunkReporter.contextual({
            logInfo: { logLevel: 'error', logger },
            event: 'risk',
            action: 'getRiskToken',
            activityInfo: {
              status: 'error',
              riskProfileToken: this._data.riskProfileToken,
            },
          });
          rej(e);
        }
      };
      initProfiling(
        {
          appGroup: 'Payments',
          assetAlias: 'Intuit.spi.icn_client.2', // TODO refactor + SplunkReporter
          screenName: pageName.replace('/', ''),
          companyId,
          // https://github.intuit.com/fraudprevention/device-intelligence/blob/master/src/util/logger-util.js
          logger: deviceIntelligenceLogger,
          // timeout from dynamic config
        },
        // @ts-ignore
        this.getRiskProfileTokenDiEnv()
      )
        .then(handleProfilingComplete)
        .catch(rej);
    });

  getRiskProfileToken = async ({
    featureFlags,
    //@ts-ignore
    mrjsInstance = window && window['mrjsInstance'],
    pageName = (window && window.__NEXT_DATA__ && window.__NEXT_DATA__.page) || 'unknown_page_name',
  }: {
    featureFlags: FeatureFlags;
    mrjsInstance?: { getInteractionId: () => Promise<string> };
    pageName?: string;
    ssrtid: string;
    token: string;
  }) => {
    let riskProfileToken = null;
    try {
      if (!this.shouldGetRiskAssessment()) {
        return riskProfileToken;
      }
      if (featureFlags['new-di-risk-library']) {
        return this.getRiskProfileTokenDi({ pageName, companyId: this.auth.realmId });
      }
      if (!mrjsInstance) {
        mrjsInstance = await this.initRiskJS({ pageName, featureFlags });
      }
      if (typeof mrjsInstance === 'object' && typeof mrjsInstance.getInteractionId !== 'function') {
        throw new Error('money risk.js missing getInteractionId function');
      }
      riskProfileToken = await mrjsInstance.getInteractionId();
      splunkReporter.setClientSession({ riskProfileToken });
      this._data.riskProfileToken = riskProfileToken;

      splunkReporter.contextual({
        logInfo: { logLevel: 'info', logger },
        event: 'risk',
        action: 'getRiskToken',
        activityInfo: {
          status: 'success',
          riskProfileToken,
        },
      });
      return riskProfileToken;
    } catch (error: any) {
      splunkReporter.contextual({
        logInfo: { logLevel: 'error', logger },
        event: 'risk',
        action: 'getRiskToken',
        activityInfo: {
          status: 'error',
          riskProfileToken,
        },
      });
      return 'riskjs-error';
    }
  };

  reportPageView(payload: any) {
    // It will use the imported reportPageView as we dont use "this"
    reportPageView(payload);
    wavefrontReporter.trackView();
  }

  registerPageLoadObserver() {
    wavefrontReporter.registerPageLoadObserver();
  }

  reportInvoicePayInCP(payload: any) {
    // It will use the imported reportPageView as we dont use "this"
    reportInvoicePayInCP(payload);
  }
  async syncPayorCompanyBills(vendorId: any, accountId: any, accountType: any, amount: any) {
    const { domainId, token, payorUserId, payorRealmId, payorCompanyEmail, payorCompanyName } =
      this._data;
    const { ssrtid, portal } = this.config;
    const { ticket, realmId, isUserSignedIn, entityId, syncToken, authToken } = this.auth;

    try {
      const data = {
        vendorId,
        accountId,
        accountType,
        amount,
        payorUserId,
      };

      const headers = getWeakTicket({
        domainId,
        entityId,
        realmId,
        token,
        ticket,
        isUserSignedIn,
        syncToken,
        authToken,
        ssrtid,
      });
      const res = await httpClient({
        url: `/${portal}/rest/payor/bill/${payorRealmId}`,
        method: 'POST',
        endpoint: `/rest/payor/bill/${payorRealmId}`,
        headers,
        data,
      });
      const {
        status,
        data: { billPaymentId, billId },
      } = res;
      if (status === 200 && billPaymentId) {
        splunkReporter.contextual({
          logInfo: { logLevel: 'info', logger },
          event: 'bill',
          action: 'sync',
          activityInfo: {
            status: 'success',
            payorRealmId,
            payorCompanyEmail,
            payorCompanyName,
            billPaymentId,
            billId,
            VendorId: vendorId,
          },
        });
        return res.data;
      }
      return false;
    } catch (error: any) {
      splunkReporter.contextual({
        logInfo: { logLevel: 'error', logger },
        event: 'bill',
        action: 'sync',
        activityInfo: {
          status: 'error',
          payorRealmId,
          payorCompanyEmail,
          payorCompanyName,
          VendorId: vendorId,
        },
        error: {
          stack: error.stack,
          message: error.message,
        },
      });
      return false;
    }
  }

  async getPayorCompanyDetails() {
    const { domainId, token } = this._data;
    const { ticket, realmId, isUserSignedIn, entityId, syncToken, authToken, recipientEmail } =
      this.auth;

    const { ssrtid, portal } = this.config;

    try {
      const headers = Object.assign(
        { recipientemail: recipientEmail },
        getWeakTicket({
          domainId,
          entityId,
          realmId,
          token,
          ticket,
          isUserSignedIn,
          syncToken,
          authToken,
          ssrtid,
        })
      );
      const res = await httpClient({
        url: `/${portal}/rest/payor/company`,
        method: 'GET',
        headers,
        endpoint: `/${portal}/rest/payor/company`,
      });
      const {
        data: { payorRealmId, payorCompanyEmail, payorCompanyName, payorUserId },
      } = res;
      this._data.payorRealmId = payorRealmId;
      this._data.payorCompanyEmail = payorCompanyEmail;
      this._data.payorCompanyName = payorCompanyName;
      this._data.payorUserId = payorUserId;
      return res.data;
    } catch (error: any) {
      splunkReporter.contextual({
        logInfo: { logLevel: 'error', logger },
        event: 'bill',
        action: 'getCompanyIndo',
        activityInfo: {
          status: 'error',
        },
        error: {
          stack: error.stack,
          message: error.message,
        },
      });
      return undefined;
    }
  }

  get debitCardEnabled() {
    return this._data.debitCardEnabled;
  }

  get isPayable() {
    return this._data.isPayable;
  }

  get token() {
    return this._data.token;
  }

  get domainId() {
    return this._data.domainId;
  }

  get riskProfileToken() {
    return this._data.riskProfileToken;
  }

  get paymentProcessor() {
    return this._data.paymentProcessor;
  }

  get offeringId() {
    return this._data.offeringId;
  }

  get companyId() {
    return this._data.companyId;
  }

  get biocatchSessionIdentifier() {
    return this.biocatchSessionId;
  }

  get view2pay() {
    return this._data.view2pay;
  }
}
