import Auth from './Auth';
import Insight from './Insight';
import Config from './Config';
import Sale from './Sale';
import CompanyInfo from './CompanyInfo';
import Payment from './Payment';
import Wallet from './Wallet/index';
import IXP from './IXP';
import { deepClone } from 'shared/utils';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
const splunkReporter = SplunkReporter.getInstance();
const logger = 'businessLogic/initializer';
export default function initializeBusinessLogic(initialState) {
  try {
    // Unfreezing the state by deep cloning because store.getState returns a frozen object
    initialState = deepClone(initialState);
  } catch (e) {
    splunkReporter.contextual({
      logInfo: { logLevel: 'error', logger },
      event: 'viewSale',
      action: 'initStore',
      activityInfo: {
        status: 'error',
      },
      error: {
        message: e.message,
        stack: e.stack,
      },
    });
  }
  const config = new Config(initialState);
  const auth = new Auth({ initialState, config });
  const companyInfo = new CompanyInfo({ initialState, config });
  const insight = new Insight({ initialState, config, auth, companyInfo });
  const sale = new Sale({ initialState, config, auth, insight });
  const payment = new Payment({ initialState, config, auth, sale, companyInfo, insight });
  const wallet = new Wallet();
  const ixp = new IXP();
  return {
    auth,
    config,
    sale,
    payment,
    insight,
    companyInfo,
    wallet,
    ixp,
  };
}
