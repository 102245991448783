import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

import { downloadPDF, formatStatus, getReciptMTLLabels } from './helpers';

import { getSurveySettingBL, submitCRMSurveyBL } from 'businessLogic/Mailchimp/Messaging';
import InvoiceViewer from 'components/Core/Invoice/Viewer/InvoiceViewer';
import HttpClient from 'server/helpers/HttpClient';
import { InvoiceFeedback } from 'server/services/mailchimp/MessagingService';
import { isPaymentRequest } from 'shared/utils';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import { modalActions } from 'store/modal/slice';
import { saleSelectors } from 'store/sale/selectors';
import { RootState } from 'store/store';
import { sliceFactory, thunkActionFactory } from 'store/utils';
import { InvoiceDocument } from 'types/InvoiceDocument';
import {
  LastSuccessfulPaymentResponse,
  LastSuccessfulPaymentResponsePaymentMethod,
} from 'types/Payment';
import { DateFormat_Long, TXN_MAP } from 'types/constants';

const httpClient = HttpClient.getInstance();

const initialState: Partial<InvoiceDocument> = {};

const { reducer, actions } = sliceFactory({
  name: 'invoiceDocument',
  initialState,
  reducers: {
    fetchPDF(state) {
      state.fetchPDFStatus = TXN_MAP.STATUS.IN_PROGRESS;
    },
    fetchPDFSuccess(state, action: PayloadAction<{ blobUrl: string }>) {
      state.fetchPDFStatus = TXN_MAP.STATUS.SUCCESS;
      state.PDFDocumentBlobUrl = action.payload && action.payload.blobUrl;
    },
    fetchPDFError(state, action: PayloadAction<{ error: any }>) {
      state.fetchPDFStatus = TXN_MAP.STATUS.ERROR;
      state.PDFDocumentBlobUrl = action.payload && action.payload.error;
    },
    fetchPDFThumbnail(state) {
      state.fetchPDFThumbnailStatus = TXN_MAP.STATUS.IN_PROGRESS;
    },
    fetchPDFThumbnailSuccess(state, action: PayloadAction<{ blobUrl: string }>) {
      state.fetchPDFThumbnailStatus = TXN_MAP.STATUS.SUCCESS;
      state.PDFThumbnailBlobUrl = action.payload && action.payload.blobUrl;
    },
    fetchPDFThumbnailError(state, action: PayloadAction<{ error: any }>) {
      state.fetchPDFThumbnailStatus = TXN_MAP.STATUS.ERROR;
      state.error = action.payload && action.payload.error;
    },
    downloadPDFStart(state) {
      state.savingPDF = true;
    },
    downloadPDFDone(state) {
      state.savingPDF = false;
    },
    downloadReceiptPDFStart(state) {
      state.savingReceiptPDF = true;
    },
    downloadReceiptPDFDone(state) {
      state.savingReceiptPDF = false;
    },
  },
});

export const invoiceDocumentReducer = reducer;
export const invoiceDocumentActions = actions;

export const fetchPDFDocument = thunkActionFactory<string>(({ payload, dispatch, state }) => {
  const token = payload;
  const {
    auth: { authToken, realmId },
  } = state;
  dispatch(invoiceDocumentActions.fetchPDF());

  const headers: Record<string, string> = {
    'Content-Type': 'application/pdf',
    Accept: 'application/pdf',
    Authorization: `Bearer ${authToken}`,
    'intuit-realmid': realmId,
  };

  const url = `/${state.config.portal}/rest/pdf/${token}/invoice.pdf`;
  const endpoint = `/${state.config.portal}/rest/pdf/invoice/:token`;

  return httpClient({
    url,
    method: 'GET',
    responseType: 'blob',
    headers,
    endpoint,
    ssrtid: state.config.ssrtid,
    token,
    timeout: state.config.invoicePDFTimeoutMS,
    event: 'pdf',
  })
    .then(async (result: { data: any }) => {
      const blob = result.data;
      const blobUrl = URL.createObjectURL(blob);

      dispatch(invoiceDocumentActions.fetchPDFSuccess({ blobUrl }));

      // if the user initiate the saving process before the PDF promise returned
      if (state.invoiceDocument.savingPDF) {
        _savePDF(dispatch, state);
      }
    })
    .catch((error: Error) => {
      dispatch(invoiceDocumentActions.fetchPDFError({ error }));
    });
});

const _savePDF = (dispatch: Dispatch, state: RootState) => {
  let invoiceNumber;
  let docName = 'Invoice';
  const {
    invoiceDocument: { PDFDocumentBlobUrl },
    sale,
    // @ts-ignore
    test = false,
  } = state;

  invoiceNumber = saleSelectors.referenceNumberSelector(sale);

  if (sale && sale.type === TXN_MAP.TYPES.ESTIMATE) {
    docName = 'Estimate';
  }
  if (sale && sale.type === TXN_MAP.TYPES.CHANGE_ORDER) {
    docName = 'Change Order';
  }

  !test &&
    downloadPDF({
      fileName: `${docName} ${invoiceNumber}.pdf`,
      blobUrl: PDFDocumentBlobUrl,
    });

  dispatch(invoiceDocumentActions.downloadPDFDone());
};

export const downloadPDFDocument = thunkActionFactory<boolean>(({ dispatch, state }) => {
  dispatch(invoiceDocumentActions.downloadPDFStart());
  const {
    invoiceDocument: { fetchPDFStatus },
  } = state;

  if (fetchPDFStatus === TXN_MAP.STATUS.SUCCESS) {
    setTimeout(_savePDF, 350, dispatch, state);
  } else if (fetchPDFStatus === TXN_MAP.STATUS.ERROR) {
    setTimeout(dispatch, 350, invoiceDocumentActions.downloadPDFDone());
  }
});

export const saveReceiptAsPdf = thunkActionFactory<{
  intl: { formatDate: (arg1: number | undefined, arg2: typeof DateFormat_Long) => any };
}>(({ payload, state, dispatch, businessLogic }) => {
  const { intl } = payload || {};
  dispatch(invoiceDocumentActions.downloadReceiptPDFStart());
  let transactionType, companyAddressExtended;
  const {
    sale,
    companyInfo,
    payment: { lastSuccessfulPaymentResponse, paymentMethodType },
    config: { ssrtid, portal },
    insight: { token },
    auth: { authToken, realmId },
  } = state;

  // @ts-ignore
  const { jwt, maskedAccountNumber, paymentDate, intuit_tid, paymentMethod } =
    lastSuccessfulPaymentResponse || {};

  transactionType = saleSelectors.typeSelector(sale);
  companyAddressExtended = companyInfoSelectors.addressSelector(companyInfo);
  const { formatDate } = intl || {};

  let companyAddress;
  if (companyAddressExtended) {
    const { street1 = '', city = '', stateOrRegion = '', postalCode = '' } = companyAddressExtended;
    if (city !== null && stateOrRegion !== null) {
      companyAddress = `${street1 !== null ? street1 + ',' : ''}
        ${city}, ${stateOrRegion} ${postalCode !== null ? postalCode : ''}`;
    }
  }
  let convertedPaymentMethod;
  if (
    paymentMethodType === 'pp' ||
    paymentMethodType === 'paypal_ppaam' ||
    paymentMethod === 'PayPalCommerce'
  ) {
    convertedPaymentMethod = 'PayPal';
  } else if (paymentMethodType === 'venmo') {
    convertedPaymentMethod = 'Venmo';
  } else {
    convertedPaymentMethod = maskedAccountNumber;
  }
  const formattedPaidDate = formatDate ? formatDate(paymentDate, DateFormat_Long) : undefined;

  let receipt, receiptType, docId;
  if (isPaymentRequest(transactionType)) {
    receipt = getPaymentReqReceiptContent(
      intl,
      state,
      convertedPaymentMethod as LastSuccessfulPaymentResponsePaymentMethod,
      companyAddress,
      formattedPaidDate
    );
    receiptType = TXN_MAP.RECEIPT_TYPES.PAYMENT_REQ_RECEIPT;
  } else {
    // @ts-ignore
    if (businessLogic.sale.requiresDeposit) {
      receipt = getDepositReceiptContent(
        intl,
        state,
        convertedPaymentMethod as LastSuccessfulPaymentResponsePaymentMethod,
        companyAddress,
        formattedPaidDate
      );
      receiptType = TXN_MAP.RECEIPT_TYPES.DEPOSIT_RECEIPT;
      docId = receipt?.invoiceNumber;
    } else {
      receipt = getInvoiceReceiptContent(
        intl,
        state,
        convertedPaymentMethod as LastSuccessfulPaymentResponsePaymentMethod,
        companyAddress,
        formattedPaidDate
      );
      receiptType = TXN_MAP.RECEIPT_TYPES.INVOICE_RECEIPT;
      docId = receipt?.invoiceNumber;
    }
  }

  const passedParams = { intuit_tid, ssrtid, token, authToken, realmId, docId, ...receipt };
  postCreateReceipt(passedParams, portal, receiptType, jwt);

  setTimeout(() => dispatch(invoiceDocumentActions.downloadReceiptPDFDone()), 500);
});

const getPaymentReqReceiptContent = (
  intl: any,
  state: RootState,
  paymentMethod: LastSuccessfulPaymentResponsePaymentMethod,
  companyAddress: string | undefined,
  formattedPaidDate: any
) => {
  let companyName,
    companyEmail,
    companyPhone,
    companyWebAddr,
    companyLogoUrl,
    currency,
    txnDate,
    description;
  const {
    sale,
    companyInfo,
    payment: { lastSuccessfulPaymentResponse },
  } = state;
  // @ts-ignore
  const { amountPaid, trackingId } = lastSuccessfulPaymentResponse || {};

  companyName = companyInfoSelectors.nameSelector(companyInfo);
  companyEmail = companyInfoSelectors.emailSelector(companyInfo);
  companyPhone = companyInfoSelectors.phoneSelector(companyInfo);
  companyWebAddr = companyInfoSelectors.webAddrSelector(companyInfo);
  companyLogoUrl = companyInfoSelectors.logoSelector(companyInfo);
  currency = saleSelectors.currencySelector(sale);
  txnDate = saleSelectors.txnDateSelector(sale);
  description = saleSelectors.descriptionSelector(sale);

  const { formatMessage, formatNumber } = intl;
  return {
    youPaidLabel: formatMessage({ id: 'PAYFLOW_YOU_PAID', defaultValue: 'You paid' }),
    toLabel: formatMessage({ id: 'PAYFLOW_TO', defaultValue: 'To' }),
    paymentReceiptLabel: formatMessage({
      id: 'PAYFLOW_PAYMENT_RECEIPT',
      defaultValue: 'Payment Receipt',
    }),
    onLabel: formatMessage({ id: 'PAYFLOW_ON', defaultValue: 'On' }),
    requestDateLabel: formatMessage({ id: 'PAYMENT_REQUEST_DATE', defaultValue: 'Request date' }),
    descriptionLabel: formatMessage({
      id: 'PAYMENT_REQUEST_DESCRIPTION',
      defaultValue: 'Description of goods or services',
    }),
    totalPaidLabel: formatMessage({ id: 'INVOICE_TOTAL', defaultValue: 'Total' }),
    paymentMethodLabel: formatMessage({
      id: 'PAYFLOW_PAYMENT_METHOD',
      defaultValue: 'Payment method',
    }),
    trackingIdLabel: formatMessage({ id: 'AUTHORIZATION_ID', defaultValue: 'Authorization ID' }),
    formattedTotalPaid: formatNumber(amountPaid, { style: 'currency', currency }),
    statusLabel: formatMessage({ id: 'INVOICE_SUMMARY_STATUS', defaultValue: 'Status' }),
    thankYouLabel: formatMessage({ id: 'THANK_YOU', defaultValue: 'Thank you' }),
    txnDate,
    companyName,
    companyEmail,
    companyPhone,
    companyWebAddr,
    companyAddress,
    companyLogoUrl,
    paymentMethod,
    formattedPaidDate,
    trackingId,
    description: description && description.replace(/\n/g, '%0A'),
  };
};

export const getDepositReceiptContent = (
  intl: any,
  state: RootState,
  paymentMethod: LastSuccessfulPaymentResponsePaymentMethod,
  companyAddress: string | undefined,
  formattedPaidDate: any
) => {
  let companyName,
    companyEmail,
    companyPhone,
    companyWebAddr,
    invoiceNumber,
    companyLogoUrl,
    currency,
    invoiceAmount,
    balanceAmount,
    transactionType;
  const {
    sale,
    companyInfo,
    payment: { lastSuccessfulPaymentResponse },
  } = state;
  // @ts-ignore
  const { amountPaid, trackingId } = lastSuccessfulPaymentResponse as LastSuccessfulPaymentResponse;

  companyName = companyInfoSelectors.nameSelector(companyInfo);
  companyEmail = companyInfoSelectors.emailSelector(companyInfo);
  companyPhone = companyInfoSelectors.phoneSelector(companyInfo);
  companyWebAddr = companyInfoSelectors.webAddrSelector(companyInfo);
  invoiceNumber = saleSelectors.referenceNumberSelector(sale);
  companyLogoUrl = companyInfoSelectors.logoSelector(companyInfo);
  currency = saleSelectors.currencySelector(sale);
  invoiceAmount = saleSelectors.amountSelector(sale);
  balanceAmount = saleSelectors.balanceSelector(sale);
  transactionType = saleSelectors.typeSelector(sale);

  const { formatMessage, formatNumber } = intl;
  const subtotal = amountPaid;
  const outstandingBalanceAmount =
    transactionType === TXN_MAP.TYPES.ESTIMATE ? invoiceAmount - amountPaid : balanceAmount;

  return {
    youPaidLabel: formatMessage({ id: 'PAYFLOW_YOU_PAID', defaultValue: 'You paid' }),
    toLabel: formatMessage({ id: 'PAYFLOW_TO', defaultValue: 'To' }),
    paymentReceiptLabel: formatMessage({
      id: 'PAYFLOW_PAYMENT_RECEIPT',
      defaultValue: 'Payment Receipt',
    }),
    onLabel: formatMessage({ id: 'PAYFLOW_ON', defaultValue: 'On' }),

    invoiceNumberLabel: formatMessage({
      id: 'PAYFLOW_ESTIMATE_NUMBER',
      defaultValue: 'Estimate no.',
    }),
    invoiceAmountLabel: formatMessage({
      id: 'ESTIMATE_SUMMARY_INVOICE_AMOUNT',
      defaultValue: 'Estimate amount',
    }),
    totalPaidLabel: formatMessage({ id: 'INVOICE_TOTAL', defaultValue: 'Total' }),
    paymentMethodLabel: formatMessage({
      id: 'PAYFLOW_PAYMENT_METHOD',
      defaultValue: 'Payment method',
    }),
    trackingIdLabel: formatMessage({ id: 'AUTHORIZATION_ID', defaultValue: 'Authorization ID' }),
    formattedInvoiceAmount: formatNumber(invoiceAmount, { style: 'currency', currency }),
    formattedTotalPaid: formatNumber(amountPaid, { style: 'currency', currency }),

    outstandingBalanceLabel: formatMessage({
      id: 'INVOICE_SUMMARY_OUTSTANDING_BALANCE',
      defaultValue: 'Outstanding balance',
    }),
    formattedOutstandingBalance: outstandingBalanceAmount
      ? formatNumber(outstandingBalanceAmount, { style: 'currency', currency })
      : null,
    statusLabel: formatMessage({ id: 'INVOICE_SUMMARY_STATUS', defaultValue: 'Status' }),
    formattedStatus: formatMessage({
      id: 'INVOICE_SUMMARY_PARTIALLY_PAID',
      defaultValue: 'Partially paid',
    }),

    subtotalLabel: formatMessage({ id: 'INVOICE_SUMMARY_SUBTOTAL', defaultValue: 'Subtotal' }),

    formattedSubtotal: formatNumber(subtotal, { style: 'currency', currency }),

    thankYouLabel: formatMessage({ id: 'THANK_YOU', defaultValue: 'Thank you' }),

    companyName,
    invoiceNumber,
    companyEmail,
    companyPhone,
    companyWebAddr,
    companyAddress,
    companyLogoUrl,
    formattedPaidDate,
    paymentMethod,
    trackingId,
    ...getReciptMTLLabels(intl),
  };
};

// TODO: move this to the server
export const getInvoiceReceiptContent = (
  intl: any,
  state: RootState,
  paymentMethod: LastSuccessfulPaymentResponsePaymentMethod,
  companyAddress: string | undefined,
  formattedPaidDate: any
) => {
  let companyName,
    companyEmail,
    companyPhone,
    companyWebAddr,
    invoiceNumber,
    companyLogoUrl,
    currency,
    invoiceAmount,
    balanceAmount;
  const {
    sale,
    companyInfo,
    featureFlags = {},
    payment: { gratuityValue = 0, lastSuccessfulPaymentResponse, amount },
  } = state;
  // @ts-ignore
  const { amountPaid, trackingId } = lastSuccessfulPaymentResponse as LastSuccessfulPaymentResponse;

  companyName = companyInfoSelectors.nameSelector(companyInfo);
  companyEmail = companyInfoSelectors.emailSelector(companyInfo);
  companyPhone = companyInfoSelectors.phoneSelector(companyInfo);
  companyWebAddr = companyInfoSelectors.webAddrSelector(companyInfo);
  invoiceNumber = saleSelectors.referenceNumberSelector(sale);
  companyLogoUrl = companyInfoSelectors.logoSelector(companyInfo);
  currency = saleSelectors.currencySelector(sale);
  invoiceAmount = saleSelectors.amountSelector(sale);
  balanceAmount = saleSelectors.balanceSelector(sale);

  const { formatMessage, formatNumber } = intl;
  const subtotal = amountPaid;
  const amountAchConvenienceFee = saleSelectors.achOnlineConvenienceFeeAmountSelector(sale);
  const amountAchConveniencEnabled = saleSelectors.achOnlineConvenienceFeeEnabledSelector(sale);

  // achConvenienceFee - if invoice amount is 70$ and the fee is 15$ ,
  // amountPaidValue should be 85$
  const amountPaidValue = amountAchConveniencEnabled ? amount : amountPaid;

  let content = {
    youPaidLabel: formatMessage({ id: 'PAYFLOW_YOU_PAID', defaultValue: 'You paid' }),
    toLabel: formatMessage({ id: 'PAYFLOW_TO', defaultValue: 'To' }),
    paymentReceiptLabel: formatMessage({
      id: 'PAYFLOW_PAYMENT_RECEIPT',
      defaultValue: 'Payment Receipt',
    }),
    onLabel: formatMessage({ id: 'PAYFLOW_ON', defaultValue: 'On' }),

    invoiceNumberLabel: formatMessage({ id: 'PAYFLOW_INVOCE_NUMBER', defaultValue: 'Invoice no.' }),
    invoiceAmountLabel: formatMessage({
      id: 'INVOICE_SUMMARY_INVOICE_AMOUNT',
      defaultValue: 'Invoice amount',
    }),
    totalPaidLabel: formatMessage({ id: 'INVOICE_TOTAL', defaultValue: 'Total' }),
    paymentMethodLabel: formatMessage({
      id: 'PAYFLOW_PAYMENT_METHOD',
      defaultValue: 'Payment method',
    }),
    trackingIdLabel: formatMessage({ id: 'AUTHORIZATION_ID', defaultValue: 'Authorization ID' }),
    formattedInvoiceAmount: formatNumber(invoiceAmount, { style: 'currency', currency }),
    formattedTotalPaid: formatNumber(amountPaidValue, { style: 'currency', currency }),

    outstandingBalanceLabel: formatMessage({
      id: 'INVOICE_SUMMARY_OUTSTANDING_BALANCE',
      defaultValue: 'Outstanding balance',
    }),
    formattedOutstandingBalance:
      balanceAmount > 0 ? formatNumber(balanceAmount, { style: 'currency', currency }) : null,

    creditLabel: formatMessage({ id: 'INVOICE_SUMMARY_CREDIT', defaultValue: 'Credit' }),
    formattedCredit:
      balanceAmount < 0
        ? '+' + formatNumber(Math.abs(balanceAmount), { style: 'currency', currency })
        : null,

    statusLabel: formatMessage({ id: 'INVOICE_SUMMARY_STATUS', defaultValue: 'Status' }),
    formattedStatus:
      balanceAmount < 0
        ? formatMessage({ id: 'INVOICE_SUMMARY_OVER_PAID', defaultValue: 'Over paid' })
        : balanceAmount > 0
        ? formatMessage({ id: 'INVOICE_SUMMARY_PARTIALLY_PAID', defaultValue: 'Partially paid' })
        : formatMessage({ id: 'PAYNOW_PAYMENT_FULL', defaultValue: 'Paid' }),

    subtotalLabel: formatMessage({ id: 'INVOICE_SUMMARY_SUBTOTAL', defaultValue: 'Subtotal' }),

    formattedSubtotal: formatNumber(subtotal, { style: 'currency', currency }),

    thankYouLabel: formatMessage({ id: 'THANK_YOU', defaultValue: 'Thank you' }),

    achConvenienceFeeLabel: amountAchConveniencEnabled
      ? formatMessage({
          id: 'ACH_CONVENIENCE_FEE_RECEIPT_MESSAGE',
          defaultValue: 'Online convenience fee',
        })
      : undefined,
    achConvenienceFeeAmount:
      amountAchConveniencEnabled && (amountAchConvenienceFee ?? 0) > 0
        ? formatNumber(amountAchConvenienceFee, { style: 'currency', currency })
        : undefined,

    companyName,
    invoiceNumber,
    companyEmail,
    companyPhone,
    companyWebAddr,
    companyAddress,
    companyLogoUrl,
    formattedPaidDate,
    paymentMethod,
    trackingId,
    ...getReciptMTLLabels(intl),
  };

  if (featureFlags && featureFlags['gratuity-enabled'] && gratuityValue && gratuityValue > 0) {
    //fully paid invoice with tip lead to negative values
    content = {
      ...content,
      // @ts-ignore
      gratuityLabel: formatMessage({
        id: 'INVOICE_SUMMARY_INVOICE_TIP',
        defaultValue: 'Tip',
      }),
      formattedGratuityAmount: formatNumber(gratuityValue, { style: 'currency', currency }),
      formattedOutstandingBalance:
        balanceAmount > 0 ? formatNumber(balanceAmount, { style: 'currency', currency }) : null,
      //Credit remove because Over Payment with Tips is not supported.
      formattedCredit: null,
      creditLabel: null,
      formattedStatus: formatStatus({ amountPaid, gratuityValue, balanceAmount, intl }),
    };
  }

  return content;
};

export const postCreateReceipt = async (
  { authToken, realmId, token, intuit_tid, ssrtid, ...passedParams }: any,
  portal: string,
  receiptType: string,
  jwt: string
) => {
  await httpClient
    .post(
      `/${portal}/rest/pdf/${receiptType}/receipt.pdf`,
      { ...passedParams, jwt },
      {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${authToken}`,
          'intuit-realmid': realmId,
          ssrtid: ssrtid,
          'ssr-session-id': ssrtid,
          intuit_tid: intuit_tid,
          'intuit-acstoken': token,
        },
        endpoint: `/${portal}/rest/pdf/${receiptType}/receipt.pdf`,
      }
    )
    .then((res) => {
      const blob = res.data;
      const blobUrl = URL.createObjectURL(blob);
      let fileName = '';
      const docId = passedParams?.docId;
      if (docId) {
        fileName = `Receipt ${docId}.pdf`;
      } else {
        fileName = 'Receipt.pdf';
      }

      downloadPDF({ fileName, blobUrl });
    });
};
export const showInvoiceViewer = thunkActionFactory(async ({ dispatch }) => {
  dispatch(
    modalActions.show({
      component: InvoiceViewer as unknown as () => JSX.Element,
      componentProps: { closeOnClickOutSide: true, closeOnEsc: false, showCloseButton: true },
    })
  );
});

// TODO: (RevIntel) move to postInvoiceSurvey/slice.ts
export const submitCRMSurvey = thunkActionFactory<{
  feedback: InvoiceFeedback;
}>(async ({ payload, state }) => {
  const {
    config: { ssrtid, portal },
    insight: { token },
    auth: { authToken, realmId },
    companyInfo,
    sale,
  } = state;
  const { feedback } = payload || {};

  const companyTxnId = saleSelectors.idSelector(sale);
  const companyEmail = companyInfoSelectors.emailSelector(companyInfo)
    ? (companyInfoSelectors.emailSelector(companyInfo) as string)
    : '';
  const payorNameId = saleSelectors.customerLocalNameIdSelector(sale) ?? '';
  const customerDisplayName = saleSelectors.customerNameSelector(sale);
  const locale = companyInfoSelectors.localeSelector(companyInfo);

  const resp = await submitCRMSurveyBL({
    ssrtid,
    portal,
    acsToken: token,
    realmId,
    authToken,
    data: {
      companyTxnId,
      companyEmail,
      customerDisplayName,
      payorNameId,
      feedback,
      locale,
    },
  });

  return resp;
});

// TODO: (RevIntel) remove me once new survey settings are fully rolled out
export const fetchSurveySetting = thunkActionFactory(async ({ state }) => {
  const {
    config: { ssrtid, portal },
    insight: { token },
    auth: { authToken, realmId },
  } = state;

  const resp = await getSurveySettingBL({
    ssrtid,
    portal,
    acsToken: token,
    realmId,
    authToken,
  });

  return resp;
});
