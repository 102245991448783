import { displayGratuityFeature } from 'businessLogic/Payment/Gratuity';
import HttpClient from 'server/helpers/HttpClient';
import { companyInfoSelectors } from 'store/companyInfo/selectors';
import { saleSelectors } from 'store/sale/selectors';
import { isFrictionlessLoginEligibleSelector } from 'store/auth/selectors';

/*
 * For analytics we should know if current auth session was done with Frictionless
 * Doing that on a BE side will be too expensive: a call to Identity should be done each page view
 * So this value is persisted on the FE in local storage strictly for analytics use-case
 * It is scoped with auth.ticket not to worry about invalidation
 * If same user will log out and log in again *without* frictionless, his ticket is changed
 */
const getPersistedFrictionlessLoggedIn = (ticket) => {
  return window.localStorage.getItem('frictionless_logged_in_ticket') === ticket;
};

export const persistFrictionlessLoggedIn = (ticket) => {
  // Actually does not matter what value is set, just presence of the key is important
  window.localStorage.setItem('frictionless_logged_in_ticket', ticket);
};

function getFeatureEligibility(store = {}) {
  const { sale, insight = {}, featureFlags = {}, auth = {} } = store;
  const {
    isPayable,
    shouldShowSchedulePay,
    shouldShowAutopay,
    payorSegmentation: { hasMintAccount, hasMailchimpAccount, hasQuickbooksAccount } = {},
  } = insight;
  const { ticket } = auth;
  const feature_eligibility = [];
  feature_eligibility.push(getMerchantLogoStatus(store));

  if (isPayable) {
    const isTipsEnabled = displayGratuityFeature(store);
    if (isTipsEnabled) {
      feature_eligibility.push('tips');
    }
  }
  if (shouldShowSchedulePay) {
    feature_eligibility.push('schedule_pay');
  }
  if (shouldShowAutopay) {
    feature_eligibility.push('auto_pay');
  }
  if (
    isFrictionlessLoginEligibleSelector({
      featureFlags,
      auth,
      sale,
    })
  ) {
    feature_eligibility.push('isfrictionlesseligible');
  }
  if (getPersistedFrictionlessLoggedIn(ticket)) {
    feature_eligibility.push('authentication_type:frictionless');
  }
  if (hasMintAccount || hasMailchimpAccount || hasQuickbooksAccount) {
    const account = hasQuickbooksAccount
      ? 'qbo'
      : hasMailchimpAccount
      ? 'mailchimp'
      : 'creditkarma';
    feature_eligibility.push(`${account}_login`);
  }
  if (saleSelectors.achOnlineConvenienceFeeEnabledSelector(sale)) {
    const feeAmount =
      saleSelectors.achOnlineConvenienceFeeAmountPaidSelector(sale) ||
      saleSelectors.achOnlineConvenienceFeeAmountSelector(sale);
    feature_eligibility.push(`convenience_fee_amount_${feeAmount}`);
  }
  if (getGrowthExperimentUtm() !== null) {
    feature_eligibility.push(getGrowthExperimentUtm());
  }
  return feature_eligibility;
}

function getMerchantLogoStatus(store) {
  const { companyInfo = {} } = store;
  let merchantLogoStatus = '',
    companyLogoUrl = '';
  companyLogoUrl = companyInfoSelectors.logoSelector(companyInfo);

  merchantLogoStatus = companyLogoUrl ? 'MerchantHasLogo' : 'MerchantHasNoLogo';
  return merchantLogoStatus;
}

function getScreenPrevious() {
  const isFromSignIn = (localStorage?.getItem('from_sign_in') ?? 'false') === 'true';
  const isFromSignUp = (localStorage?.getItem('from_sign_up') ?? 'false') === 'true';

  let result = '';
  if (isFromSignIn) {
    result = 'sign_in_page';
    localStorage.removeItem('from_sign_in');
  } else if (isFromSignUp) {
    result = 'sign_up_page';
    localStorage.removeItem('from_sign_up');
  }
  return result;
}

function getLinkSource() {
  try {
    const queryString = new URLSearchParams(window?.location?.search);
    const utm = queryString.get('utm_source');
    const cta = queryString.get('cta');

    const ctaOptionsMap = {
      viewinvoicenow: 'email',
      pdfbutton: 'pdfbutton',
      paylinkbuybutton: 'paylinkbuybutton',
    };

    const utmOptionsMap = {
      sms: 'sms',
      sharelink: 'sharelink',
    };

    if (cta in ctaOptionsMap) {
      return ctaOptionsMap[cta];
    }
    if (utm in utmOptionsMap) {
      return utmOptionsMap[utm];
    }

    return null;
  } catch (e) {
    return null;
  }
}
function getGrowthExperimentUtm() {
  try {
    const queryString = new URLSearchParams(window?.location?.search);
    const grwParam = queryString.get('grw');

    const growthExperimentsOptionsMap = {
      // key-values for growth-experiments [due-date, pre-header]:
      ddet_control: 'ddet_control',
      ddet_t1: 'ddet_t1',
      ddet_t2: 'ddet_t2',
      ddph_control: 'ddph_control',
      ddph_t1: 'ddph_t1',
      ddph_t2: 'ddph_t2',
    };

    if (grwParam in growthExperimentsOptionsMap) {
      return growthExperimentsOptionsMap[grwParam];
    }
    return null;
  } catch (e) {
    return null;
  }
}

async function isAdBlockEnabled() {
  const handleAdBlockCheckHttpError = () => {
    isAdBlockEnabled = true;
  };
  let isAdBlockEnabled = false;
  const googleAdUrl = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';

  try {
    await HttpClient.getInstance()
      .get(googleAdUrl)
      .catch((e) => {
        handleAdBlockCheckHttpError(e);
      });
  } catch (e) {
    handleAdBlockCheckHttpError(e);
  }
  return isAdBlockEnabled;
}

export {
  getFeatureEligibility,
  getScreenPrevious,
  getMerchantLogoStatus,
  isAdBlockEnabled,
  getLinkSource,
  getGrowthExperimentUtm,
};
