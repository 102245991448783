type AsyncResolverCallback<T> = (resolvedValue: T | undefined) => void;

export interface AsyncResolver<T> {
  register: (asyncResolver: AsyncResolverCallback<T>) => void;
  resolve: (value: T) => void;
}

/*
This is a poor man's excuse for observables.
Useful if you have an event that you need to await but it's not a promise and not stored anywhere
 */
export const initAsyncResolver = <T>(): AsyncResolver<T> => {
  let isResolved = false;
  let resolvedValue: T | undefined;
  const cbs: Record<number, AsyncResolverCallback<T>> = {};

  const resolve = (value: T) => {
    isResolved = true;
    resolvedValue = value;
    Object.values(cbs).forEach((cb) => cb(value));
  };

  const register = (cb: AsyncResolverCallback<T>) => {
    if (isResolved) {
      cb(resolvedValue);
      return;
    }

    cbs[Math.random()] = cb;
  };

  return {
    resolve,
    register,
  };
};

export const promisifyAsyncResolver = <T>(
  asyncResolver: AsyncResolver<T>,
  timeout?: number
): Promise<T | undefined> => {
  return new Promise((resolve, reject) => {
    asyncResolver.register(resolve);
    if (timeout) {
      setTimeout(() => reject(new Error('Timeout')), timeout);
    }
  });
};
