import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import InfoCard from 'components/Core/Nanopay/InfoCard/InfoCard';
import Spinner from 'components/Shared/Spinner/Spinner';
import { modalActions } from 'store/modal/slice';
import { logInfoNanopayFlow } from 'store/nanopay/nanopay';
import { nanopayActions, updateFlinksData } from 'store/nanopay/slice';
import { colors } from 'styles/cp';
import { Config } from 'types/Config';
import { Nanopay } from 'types/Nanopay';
import { LOG_FLINK_STEP } from 'types/constants';

type logFlinksEventProps = {
  step: LOG_FLINK_STEP;
  flinksCode: object;
  loginId: string;
  accountId: string;
  requestId: string;
  institution: string;
};

type Props = {
  config: Pick<Config, 'ssrtid' | 'endpoints'>;
  nanopay: Pick<Nanopay, 'tokenId' | 'nanopayUrl'>;
};

const isCancelledEvent = ({ step }: { step: string }) => step === LOG_FLINK_STEP.CANCELLED;
const isSuccessEvent = ({ step }: { step: string }) => step === LOG_FLINK_STEP.SUCCESS;

const BankTransferCAFlinks = () => {
  const [loading, setLoading] = useState(true);
  const [pageMessage] = useState(false);
  const {
    config: { ssrtid },
    nanopay: { tokenId, nanopayUrl },
  } = useSelector((state: Props) => state);
  const dispatch = useDispatch<any>();
  const handleHideModel = () => {
    dispatch(modalActions.hide());
  };

  const nanopayMessageHandler = (e: { data: logFlinksEventProps }) => {
    if (e && e.data) {
      const message = e.data;

      if (isCancelledEvent(message)) {
        handleHideModel();
        return;
      }

      if (isSuccessEvent(message)) {
        dispatch(
          nanopayActions.nanopayCaptureOnboardingRequestData({ onboardingRequestId: tokenId })
        );
        dispatch(updateFlinksData({ hasFlinksFlowCompleted: true }));
        logInfoNanopayFlow({
          ssrtid,
          action: 'flinks_event',
          state: 'completed',
          opts: { ...message },
        });
        return;
      }

      const { institution } = message;
      if (institution) dispatch(updateFlinksData({ institution }));
      logInfoNanopayFlow({
        ssrtid,
        action: 'flinks_event',
        state: 'in-progress',
        opts: { ...message },
      });
    }
  };

  useEffect(() => {
    window.addEventListener('message', nanopayMessageHandler);
    return () => window.removeEventListener('message', nanopayMessageHandler);
  }, []);
  return (
    <>
      <style jsx>{`
        .spinner-wrapper {
          width: 100px;
          margin: 50px auto;
        }
        .message {
          width: 80%;
          margin: auto;
        }
      `}</style>
      {loading ? (
        <div className="spinner-wrapper">
          <Spinner width={100} color={colors.green} />
        </div>
      ) : (
        pageMessage && (
          <div className="message">
            <InfoCard
              title="NANOPAY_FLINKS_MESSAGE_HEADER"
              subtitle="NANOPAY_FLINKS_MESSAGE_TEXT"
            />
          </div>
        )
      )}

      <iframe
        className="flinksconnect"
        width="100%"
        height="100%"
        scrolling="yes"
        style={{ border: 0 }}
        onLoad={() => setLoading(false)}
        src={nanopayUrl}
      ></iframe>
    </>
  );
};
export default BankTransferCAFlinks;
