module.exports.colors = {
  intuit_blue: '#0077C5',
  flow_kit_blue: '#0099FF',
  black: '#000000',
  gray: '#393a3d',
  gray01: '#4a4a4a',
  gray02: '#6b6c72',
  gray03: '#8D9096',
  gray04: '#babec5',
  gray05: '#d4d7dc',
  gray06: '#E3E5E8',
  gray07: '#ECEEF1',
  gray08: '#F4F5F8',
  gray09: '#f6f6f6',
  gray10: '#dadadc',
  gray11: '#d6d6d8',
  gray12: '#c0c0c3',
  gray13: '#e5e5e5',
  gray14: '#727e85',
  white: '#ffffff',

  pepper50: '#9AA7B0',
  ghostBlack: '#262626',
  darkBlack: '#212529',
  charcoal: '#222222',
  offWhite: '#f4f5f8',
  offWhite01: '#F9F9FF',
  whiteGreen: '#f4faf3',
  green: '#2ca01c',
  green01: '#108000',
  green02: '#00ba00',
  green03: '#00a300',
  green04: '#95CF8D',
  greenKermit: '#53b700',
  orange: '#ffa146',
  warn: '#ffbb00',
  orangeWarn: '#FF8000',
  orangeBright: '#F95700',
  orangeWarn2: '#FF6A00',
  yellow: '#ffc439',
  yellow01: '#ffc438',
  yellow02: '#F2BA35',
  yellow03: '#FB0',
  error: '#e80000',
  darkError: '#d52b1e',
  redError: '#E43834',
  lightBlue: '#0097e6',
  blue: '#0079cb',
  blue01: '#0F7EA4',
  blue02: '#31c9c6',
  blue03: '#0047c0',
  blue04: '#055393',
  blue05: '#0365AC',
  whiteGray: '#8c9097',
  ghostGray: '#848588',
  lightGray: '#68686c',
  sidewalkGray: '#C4C4C4',
  darkGray: '#4a4a4a',
  transparent: 'transparent',
  stoneWhite: '#E2E9ED',
  winterMintBlue: '#EFF4F9',
};

module.exports.fontSize = {
  xxxs: '10px',
  xxs: '12px',
  xs: '14px',
  sm: '16px',
  ms: '18px',
  md2: '20px',
  md: '22px',
  ml: '24px',
  lg: '28px',
  xl: '30px',
  xxl: '32px',
  xxxl: '36px',
};

module.exports.buttonHeight = {
  mini: '24px',
  medium: '31px',
  standard: '36px',
  responsive: '40px',
  jumbo: '48px',
};

module.exports.buttonMinWidth = {
  mini: '80px',
  medium: '90px',
  standard: '100px',
  jumbo: '120px',
};

module.exports.borderRadius = {
  default: '12px',
  mobile: '30px',
};

// all padding sizes are a dividend of 8
module.exports.paddingSize = {
  xxs: '4px',
  xs: '8px',
  sm: '16px',
  md: '24px',
  lg: '32px',
  xl: '40px',
};

module.exports.breakpointsNumbers = {
  xs: 320,
  xsl: 386,
  sm: 576,
  md: 768,
  mdl: 940,
  lg: 992,
  xl: 1040,
  xxl: 1440,
};

module.exports.breakpoints = {
  xs: `${exports.breakpointsNumbers.xs}px`,
  xsl: `${exports.breakpointsNumbers.xsl}px`,
  sm: `${exports.breakpointsNumbers.sm}px`,
  md: `${exports.breakpointsNumbers.md}px`,
  mdl: `${exports.breakpointsNumbers.mdl}px`,
  lg: `${exports.breakpointsNumbers.lg}px`,
  xl: `${exports.breakpointsNumbers.xl}px`,
  xxl: `${exports.breakpointsNumbers.xxl}px`,
};
