import React, { ReactElement } from 'react';
import { connect } from 'react-redux';

import Toastr from 'components/Core/Toast/Toastr';
import { toastActions } from 'store/toast/slice';

interface ToastWrapperProps {
  currentToast: any;
  hide: Function;
  toast: object;
}

const ToastWrapper: React.FC<ToastWrapperProps> = (props): ReactElement => {
  if (props.currentToast) {
    return <Toastr hide={props.hide} {...props.currentToast} />;
  }
  return <></>;
};

function mapStateToProps(state: any) {
  return { ...state.toastr };
}

export default connect(mapStateToProps, { hide: toastActions.hide })(ToastWrapper);
