const getOSName = (userAgent) => {
  if (/iPad/.test(userAgent)) {
    return 'ios';
  } else if (/iPhone|iPod/.test(userAgent)) {
    return 'ios';
  } else if (/Macintosh/.test(userAgent)) {
    return 'macos';
  } else if (/Android/.test(userAgent)) {
    return 'android';
  } else if (/Windows/.test(userAgent)) {
    return 'windows';
  } else {
    return 'unknown';
  }
};

const getBrowserName = (userAgent) => {
  if (/opr\//i.test(userAgent) || !!(typeof window === 'object' && window.opr)) {
    return 'Opera';
  } else if (/ucbrowser/i.test(userAgent)) {
    return 'UC Browser';
  } else if (/samsungbrowser/i.test(userAgent)) {
    return 'Samsung Browser';
  } else if (/edg/i.test(userAgent)) {
    return 'Microsoft Edge';
  } else if (/chrome|chromium|crios/i.test(userAgent)) {
    return 'Chrome';
  } else if (/firefox|fxios/i.test(userAgent)) {
    return 'Firefox';
  } else if (/safari|applewebkit/i.test(userAgent) && !userAgent.includes('Chrome')) {
    return 'Safari';
  } else if (/trident/i.test(userAgent)) {
    return 'Internet Explorer';
  } else {
    return 'Unknown browser';
  }
};
const getBrowserVersion = (userAgent) => {
  let version;
  if (/opr\//i.test(userAgent) || !!(typeof window === 'object' && window.opr)) {
    version = userAgent.match(/opr\/(\d+)/i)[1];
  } else if (getBrowserName(userAgent) === 'Microsoft Edge') {
    version = userAgent.match(/edg\/(\d+)/i)
      ? userAgent.match(/edg\/(\d+)/i)[1]
      : userAgent.match(/Edge\/(\d+)/i)[1];
  } else if (getBrowserName(userAgent) === 'Chrome') {
    version = userAgent.match(/(chrome|chromium|crios)\/(\d+)/i)[2];
  } else if (getBrowserName(userAgent) === 'Firefox') {
    version = userAgent.match(/(firefox|fxios)\/(\d+)/i)[2];
  } else if (getBrowserName(userAgent) === 'Safari') {
    version = userAgent.match(/version\/(\d+)/i) && userAgent.match(/version\/(\d+)/i)[1];
  } else if (getBrowserName(userAgent) === 'Internet Explorer') {
    version = userAgent.match(/rv:(\d+)/i)[1];
  } else if (getBrowserName(userAgent) === 'UC Browser') {
    version = userAgent.match(/ucbrowser\/(\d+)/i)[1];
  } else if (getBrowserName(userAgent) === 'Samsung Browser') {
    version = userAgent.match(/samsungbrowser\/(\d+)/i)[1];
  } else {
    version = 'Unknown browser';
  }
  return parseInt(version);
};
const isTablet = (userAgent) => /iPad|Android|Touch/.test(userAgent);
const isIOSDevice = (userAgent) => /iPad|iPhone|iPod/.test(userAgent);
const isEdge = (userAgent) => getBrowserName(userAgent) === 'Microsoft Edge';
const isSafari = (userAgent) => getBrowserName(userAgent) === 'Safari';
const isOldSafari = (userAgent) => {
  return getBrowserName(userAgent) === 'Safari' && getBrowserVersion(userAgent) < 11;
};

const isFireFox = (userAgent) => /Firefox/.test(userAgent);
const isWebkit = (userAgent) => /WebKit/.test(userAgent);
const isAppleProduct = (userAgent) => /iPad|iPhone|iPod|Macintosh/.test(userAgent);
const isAppleMobileProduct = (userAgent) => /iPad|iPhone|iPod/.test(userAgent);
const isMobile = (userAgent) => /Mobile/.test(userAgent);
const isDesktop = (userAgent) =>
  getOSName(userAgent) === 'macos' ||
  getOSName(userAgent) === 'windows' ||
  getOSName(userAgent) === 'linux';
const isIE = (userAgent) => /Trident/.test(userAgent);
const isOutdatedBrowser = (userAgent) => {
  const isOldIE = getBrowserName(userAgent) === 'Internet Explorer';
  const isOldEdge =
    getBrowserName(userAgent) === 'Microsoft Edge' && getBrowserVersion(userAgent) < 45;
  const isOldFireFox = getBrowserName(userAgent) === 'Firefox' && getBrowserVersion(userAgent) < 79;
  return isOldIE || isOldSafari(userAgent) || isOldEdge || isOldFireFox;
};
const isOldEdge = (userAgent) => /Edge\/[1-9]/.test(userAgent);

module.exports = {
  isTablet,
  isIOSDevice,
  isEdge,
  isSafari,
  isOldSafari,
  isFireFox,
  isWebkit,
  isAppleProduct,
  isAppleMobileProduct,
  isMobile,
  isDesktop,
  isIE,
  isOutdatedBrowser,
  isOldEdge,
  getOSName,
  getBrowserName,
};
