export class LocalStorageUtil {
  // 1. Get from storage given a key
  static getFromStorage<T>(key: string): T | null {
    try {
      const data = localStorage.getItem(key);
      return data ? (JSON.parse(data) as T) : null;
    } catch (e) {
      return null;
    }
  }

  // 2. Set storage item given a key
  static setToStorage(key: string, value: any): void {
    try {
      const data = JSON.stringify(value);
      localStorage.setItem(key, data);
    } catch (e) {
      return;
    }
  }

  // 3. GetOrSet function that sets a value if it is not set yet
  static getOrSetStorage<T>(key: string, setValue: T): T | null {
    const existingValue = LocalStorageUtil.getFromStorage<T>(key);
    if (existingValue === null) {
      LocalStorageUtil.setToStorage(key, setValue);
      return setValue;
    }
    return existingValue;
  }

  // 4. Delete from storage given a key
  static removeFromStorage(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (e) {
      return;
    }
  }
}
