const { NotFoundError } = require('server/helpers/Errors');

class PaymentAccountNotFoundError extends NotFoundError {
  constructor(...args) {
    super(...args);
    Error.captureStackTrace(this, PaymentAccountNotFoundError);
  }
}

module.exports = {
  PaymentAccountNotFoundError,
};
