import HttpClient from 'server/helpers/HttpClient';

const httpClient = HttpClient.getInstance();

export const startPayPalFlow = (paypalToken) => {
  const paypalCheckoutUrl = `${window.paypal.checkout.urlPrefix}${paypalToken}&useraction=commit`;
  window.paypal.checkout.startFlow(paypalCheckoutUrl);
};

export const closePayPalFlow = () => {
  window.paypal.checkout.closeFlow();
};

export const createOrderOnPayPalCommerce = async (paymentsValue) => {
  const {
    auth: { entityId, realmId, ticket, isUserSignedIn, syncToken, authToken },
    config: { portal, ssrtid },
    domainId,
    token,
    companyLocale,
    invoiceId,
    currency,
    payment: { amount, ppCreateOrderKey },
  } = paymentsValue;
  try {
    const paypalEndPoint = `/${portal}/app/CommerceNetwork/view/rest/external-payment/createOrder`;
    /**
     * @type {import('axios').RawAxiosRequestHeaders}
     */
    const requestHeaders = {
      Accept: 'application/json, text/javascript, */*; q=0.01',
      'Intuit-DomainId': domainId,
      'Intuit-IntuitId': entityId,
      'Intuit-RealmId': realmId,
      'Intuit-ACSToken': token,
      sessionTicket: ticket,
      'user-signed-in': typeof isUserSignedIn === 'boolean' ? isUserSignedIn.toString() : 'false',
      syncToken: syncToken,
      'ssr-session-id': ssrtid,
      'x-forwarded-for': '127.0.0.1',
      invoiceId: invoiceId,
      'invoice-amount': amount,
      currencyCode: currency,
      'pp-create-order-key': ppCreateOrderKey,
      Authorization: `Bearer ${authToken}`,
    };

    const paypalOrderIdCapture = await httpClient({
      url: paypalEndPoint,
      method: 'GET',
      endpoint: paypalEndPoint,
      headers: requestHeaders,
    });
    if (paypalOrderIdCapture.data.merchantDetailsResp === '') {
      const error = new Error('No Order Id Returned from PayPal');
      error.invoiceInfo = {
        ssrtid,
        companyLocale,
        token,
        ppCreateOrderKey,
      };
      throw error;
    }
    return paypalOrderIdCapture.data.merchantDetailsResp;
  } catch (error) {
    error.invoiceInfo = {
      ssrtid,
      companyLocale,
      token,
      ppCreateOrderKey,
    };
    throw error;
  }
};
