import { saleSelectors } from 'store/sale/selectors';
import { FeatureFlags } from 'types/FeatureFlags';
import { Sale } from 'types/Sale';
import { Wallet } from 'types/Wallet';
import { PaymentMethod, PaymentMethodSubType } from 'types/constants';

type isMAIPasPDFargs = {
  sale: Pick<Sale, 'type' | 'contact'>;
  featureFlags: FeatureFlags;
};

// In case invoice created without payor email
export const isMAIPasPDF = ({ sale }: isMAIPasPDFargs) => {
  const saleType = saleSelectors.typeSelector(sale);
  const payorEmails = saleSelectors.toEmailsSelector(sale);
  return !!(saleType === 'INVOICE' && payorEmails?.length === 0);
};

export const concatPaymentMethodWithSubType = (
  paymentMethodType: PaymentMethod,
  paymentMethodSubType?: PaymentMethodSubType
): PaymentMethod => {
  return `${paymentMethodType}${
    paymentMethodSubType ? '_' + paymentMethodSubType : ''
  }` as PaymentMethod;
};

export const isPaymentMethodWithSubTypeEnabled = ({
  wallet,
  paymentMethodSubType,
}: {
  wallet: Wallet;
  paymentMethodSubType: PaymentMethodSubType;
}) => {
  switch (paymentMethodSubType) {
    case 'paylater':
      return wallet.isPPAAMInstallmentsEnabled;
  }
};
