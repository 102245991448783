import HttpClient from 'server/helpers/HttpClient';
const httpClient = HttpClient.getInstance();
import {
  IDisplaySurveyRequestParams,
  InvoiceFeedback,
  ISurveyEngagementRequestParams,
  SurveySettingsType,
} from 'server/services/mailchimp/MessagingService';
import { getWeakTicket } from 'shared/clientUtils';

export interface BaseParams {
  ssrtid: string;
  portal: string;
  realmId: string;
  acsToken: string;
  authToken: string;
}
export interface SubmitCRMSurveyParams extends BaseParams {
  data: {
    companyTxnId: string;
    companyEmail: string;
    payorNameId: string;
    customerDisplayName: string;
    locale: string;
    feedback: InvoiceFeedback;
  };
}

export interface GetSurveyEngagementRequestParams extends BaseParams {
  data: ISurveyEngagementRequestParams;
}

export type GetSurveyEngagementResponse = { responses: Array<any> };

export interface GetSurveySettingParams extends BaseParams {}
export interface GetSurveySettingResponseData {
  qboRealmId: string;
  settingsKey: SurveySettingsType;
  settingsValue: string;
}

export interface GetSurveySettingsParams extends BaseParams {}

export type GetSurveySettingsResponseData = Array<GetSurveySettingResponseData>;

export interface GetDisplaySurveyRequestParams extends BaseParams {
  // Move header params to BaseParams and update all BL func to use shared/clientUtils.js >> getWeakTicket() later
  // <------ Header params starts
  domainId: string;
  entityId: string;
  ticket: string;
  isUserSignedIn: boolean;
  syncToken: string;
  // Header params ends ---->
  data: IDisplaySurveyRequestParams;
}
export type DisplaySurvey = { type: SurveySettingsType };
export type GetDisplaySurveyResponse = { surveys: Array<DisplaySurvey> };

export const getSurveySettingBL = async (args: GetSurveySettingParams) => {
  const { ssrtid, portal, realmId, acsToken, authToken } = args;
  const restEndpoint = `/${portal}/rest/mailchimp/survey-setting`;

  const resp = await httpClient({
    url: restEndpoint,
    method: 'GET',
    responseType: 'json',
    endpoint: restEndpoint,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'intuit-realmid': realmId,
      'ssr-session-id': ssrtid,
      'intuit-acstoken': acsToken,
    },
  });
  return resp;
};

export const getSurveySettingsBL = async (args: GetSurveySettingsParams) => {
  const { ssrtid, portal, realmId, acsToken, authToken } = args;
  const restEndpoint = `/${portal}/rest/mailchimp/survey-settings`;

  const resp = await httpClient({
    url: restEndpoint,
    method: 'GET',
    responseType: 'json',
    endpoint: restEndpoint,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'intuit-realmid': realmId,
      'ssr-session-id': ssrtid,
      'intuit-acstoken': acsToken,
    },
  });
  return resp;
};

export const submitCRMSurveyBL = async (args: SubmitCRMSurveyParams) => {
  const { ssrtid, portal, realmId, acsToken, authToken } = args;
  const restEndpoint = `/${portal}/rest/mailchimp/submit-post-invoice-feedback`;

  const resp = await httpClient({
    url: restEndpoint,
    method: 'POST',
    data: args.data,
    responseType: 'json',
    endpoint: restEndpoint,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'intuit-realmid': realmId,
      'ssr-session-id': ssrtid,
      'intuit-acstoken': acsToken,
    },
  });
  return resp;
};

export const getSurveyEngagementBL = async (args: GetSurveyEngagementRequestParams) => {
  const { ssrtid, portal, realmId, acsToken, authToken } = args;
  const restEndpoint = `/${portal}/rest/mailchimp/survey-engagement`;
  const resp = await httpClient({
    url: restEndpoint,
    method: 'GET',
    responseType: 'json',
    endpoint: restEndpoint,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'intuit-realmid': realmId,
      'ssr-session-id': ssrtid,
      'intuit-acstoken': acsToken,
    },
    params: args.data,
  });
  return resp;
};

export const getDisplaySurveyBL = async (args: GetDisplaySurveyRequestParams) => {
  const {
    ssrtid,
    portal,
    realmId,
    acsToken,
    authToken,
    domainId,
    entityId,
    ticket,
    isUserSignedIn,
    syncToken,
    data,
  } = args;

  const restEndpoint = `/${portal}/rest/mailchimp/display-survey`;
  const headers = getWeakTicket({
    domainId,
    entityId,
    realmId,
    token: acsToken,
    ticket,
    isUserSignedIn,
    syncToken,
    authToken,
    ssrtid,
  });

  return await httpClient({
    url: restEndpoint,
    method: 'GET',
    responseType: 'json',
    endpoint: restEndpoint,
    headers: headers,
    params: data,
  });
};
