/*
  This is the main util functions file which should contain
 */

const { PaymentAccountNotFoundError } = require('server/services/payment/PaymentAccountErrors');
const {
  BadRequestError,
  AuthorizationError,
  GatewayTimeoutError,
  ApplicationError,
  ConnectionError,
  NotFoundError,
  ForbiddenError,
  AuthenticationError,
  TimeoutError,
  GoneError,
  PreconditionFailedError,
  SCSTokenExpiredError,
} = require('server/helpers/Errors');
const HttpStatus = require('http-status-codes');
const { TXN_MAP } = require('types/constants');

const VERIFICATION_ERROR = 499;
const TimeoutErrorCode = 'ECONNABORTED';
const PAYMENT_ACCOUNT_DOMAIN_PREFIX = 'paymentaccount';
const SCS_DOMAIN_PREFIX = '//salescheckout';
const SCS_VALIDATION_ERROR = 'SCS_5512';

const ExternalPaymentMethodType = {
  PAYPAL: 'PAYPAL',
  PAYPAL_COMMERCE: 'PAYPAL_COMMERCE',
};

/*
      Method that returns error for each status code.
    */
const statusCodeToError = (error) => {
  let ErrorIntuitTid = error.ErrorIntuitTid || 'na';
  let errorHeaders = error.headers || {};

  try {
    if (error && error.config && error.config.headers) {
      ErrorIntuitTid = error.config.headers['intuit_tid'];
    }
    if (
      error &&
      error.response &&
      error.response.headers &&
      error.response.headers['intuit_remediations']
    ) {
      errorHeaders['intuit_remediations'] = error.response.headers['intuit_remediations'];
    }
    if (error && error.response && error.response.data) {
      const SCSValidationErrors = error.response.data.errors;
      if (
        SCSValidationErrors &&
        SCSValidationErrors[0] &&
        SCSValidationErrors[0].code === SCS_VALIDATION_ERROR
      ) {
        return new BadRequestError('SCS Sale Validation Error', ErrorIntuitTid);
      }
    }
    if (error.code === TimeoutErrorCode) {
      return new TimeoutError(error.message, ErrorIntuitTid);
    }

    const errorStatus =
      error.status || error.statusCode || (error.response && error.response.status);

    if (!errorStatus) {
      return new ApplicationError(error.message, ErrorIntuitTid, errorHeaders);
    }

    switch (errorStatus) {
      case HttpStatus.BAD_REQUEST:
        return new BadRequestError('Bad request', ErrorIntuitTid, errorHeaders);

      case HttpStatus.UNAUTHORIZED:
        return new AuthorizationError('Authorization Error', ErrorIntuitTid, errorHeaders);

      case HttpStatus.REQUEST_TIMEOUT:
        return new TimeoutError(error.message, ErrorIntuitTid, errorHeaders);

      case HttpStatus.FORBIDDEN:
        return new ForbiddenError('Authentication Error', ErrorIntuitTid, errorHeaders);

      case HttpStatus.GONE:
        return new GoneError('Resource is gone', ErrorIntuitTid, errorHeaders);

      case HttpStatus.PRECONDITION_FAILED:
        if (error.config && error.config.url && error.config.url.indexOf(SCS_DOMAIN_PREFIX) > -1) {
          return new SCSTokenExpiredError('Token Expired', ErrorIntuitTid, errorHeaders);
        }
        return new PreconditionFailedError('Precondition Failed', ErrorIntuitTid, errorHeaders);

      case HttpStatus.NOT_FOUND: {
        if (
          error.config &&
          error.config.url &&
          error.config.url.indexOf(PAYMENT_ACCOUNT_DOMAIN_PREFIX) > -1
        ) {
          return new PaymentAccountNotFoundError(error.message, ErrorIntuitTid, errorHeaders);
        }
        return new NotFoundError('Resource not found', ErrorIntuitTid, errorHeaders);
      }
      case HttpStatus.SERVICE_UNAVAILABLE:
        return new ConnectionError('Service Unavailable', ErrorIntuitTid, errorHeaders);

      case HttpStatus.GATEWAY_TIMEOUT:
        return new GatewayTimeoutError('Gateway Timeout', ErrorIntuitTid, errorHeaders);

      case VERIFICATION_ERROR:
        return new AuthenticationError('Invalid Ticket Error', ErrorIntuitTid, errorHeaders);

      case HttpStatus.INTERNAL_SERVER_ERROR:
        if (error.response && error.response.data) {
          return new ApplicationError(error.response.data, ErrorIntuitTid, errorHeaders);
        } else {
          return new ApplicationError('Internal Server Error', ErrorIntuitTid, errorHeaders);
        }

      default:
        return new ApplicationError('Internal Server Error', ErrorIntuitTid, errorHeaders);
    }
  } catch (err) {
    return new ApplicationError('Internal Server Error', ErrorIntuitTid, errorHeaders);
  }
};

const pbbPaymentStatusTranslator = (status) => {
  switch (status) {
    case 'PAY_BY_BANK_IN_PROGRESS':
      return TXN_MAP.STATUS.PBB_STATUSES.PENDING;
    case 'PAY_BY_BANK_CANCELLED':
      return TXN_MAP.STATUS.PBB_STATUSES.CANCELLED;
    case 'PAY_BY_BANK_FAILED':
      return TXN_MAP.STATUS.PBB_STATUSES.FAILED;
    case 'PAY_BY_BANK_APPROVED':
      return TXN_MAP.STATUS.PBB_STATUSES.APPROVED;
    case 'DECLINED':
      return TXN_MAP.STATUS.PBB_STATUSES.DECLINED;
    default:
      return TXN_MAP.STATUS.PBB_STATUSES.FAILED;
  }
};

module.exports = {
  VERIFICATION_ERROR,
  statusCodeToError,
  pbbPaymentStatusTranslator,
  ExternalPaymentMethodType,
};
