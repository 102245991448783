import { SurveySettingsType } from 'server/services/mailchimp/MessagingService';
import { Nullable } from 'types/utils';

// SurveyOpen controls which survey modal to show (Modal-1 i.e. work-request/repeat-business or Modal-2 i.e. feedback & referral), or to close it.
// Think of these states as the different steps of post invoice surveys:
//     - None: No survey modal is open
//     - RepeatBusiness: First survey modal is open. Currently, we show only work request survey on first modal.
//     - Referral: Second survey modal is open. Currently, we show feedback AND referral surveys on second modal.
// Its value will change based on user's action i.e. going from 1st modal to 2nd, just opening 1st, directly opening 2nd or closing the modal.
export enum SurveyOpen {
  None = 'NONE',
  RepeatBusiness = 'REPEAT_BUSINESS',
  Referral = 'REFERRAL',
}

export enum DisplaySurvey {
  WorkRequest = 'WorkRequest',
  FeedbackTestimonial = 'FeedbackTestimonial',
  Referral = 'Referral',
}

export interface PostInvoiceSurvey {
  surveyOpen?: SurveyOpen;
  loading?: boolean;
  // These states will be set on initial load and won't change after that based on user actions
  settingsMap?: Nullable<Record<SurveySettingsType, boolean>>;
  repeatBusinessEngagements?: Nullable<Array<any>>; // Repeat business survey engagements for the given company and customer pair
  feedbackEngagements?: Nullable<Array<any>>; // Feedback survey engagements for the given company and customer pair
  referralEngagements?: Nullable<Array<any>>; // Referral survey engagements for the given company and customer pair
  surveys?: Nullable<Array<any>>;
}
