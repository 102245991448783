import { AxiosError } from 'axios';

import type { Sale } from 'types/Sale';

import SegmentIO from 'reporting/SegmentIO';
import HttpClient from 'server/helpers/HttpClient';
import { FeatureFlags } from 'types/FeatureFlags';

const httpClient = HttpClient.getInstance();
const { SplunkReporter } = require('reporting/splunk/SplunkReporter');
const splunkReporter = SplunkReporter.getInstance();
const logger = 'businessLogic/Auth';

interface signOutParam {
  ssrtid: string;
  portal: string;
  token: string;
}

interface signInParams {
  entityId: string;
  endpoints: { signInURL: string };
}

interface signUpParams {
  entityId: string;
  endpoints: { signUpURL: string };
}

export const signInBL = ({ entityId, endpoints }: signInParams) => {
  splunkReporter.contextual({
    logInfo: { logLevel: 'info', logger },
    event: 'signIn',
    action: 'redirect',
    entityId,
    Refactored: true,
  });
  localStorage.setItem('from_sign_in', 'true');
  SegmentIO.transactionEngaged({
    activity_type: 'sign_in',
    ui_object: 'link',
    ui_action: 'clicked',
    ui_access_point: 'transaction_flow',
    ui_object_detail: 'wallet_sign_in',
  });

  window.location.href = endpoints.signInURL;
};

export const signUpBL = ({ entityId, endpoints }: signUpParams) => {
  splunkReporter.contextual({
    logInfo: { logLevel: 'info', logger },
    event: 'signUp',
    action: 'redirect',
    Refactored: true,
    UserEntityId: entityId,
  });
  localStorage.setItem('from_sign_up', 'true');
  SegmentIO.transactionEngaged({
    activity_type: 'sign_up',
    ui_object: 'link',
    ui_action: 'clicked',
    ui_access_point: 'transaction_flow',
    ui_object_detail: 'wallet_sign_up',
  });

  window.location.href = endpoints.signUpURL;
};

export const signOutBL = async ({ ssrtid, portal, token }: signOutParam) => {
  const endpoint = `/${portal}/rest/authenticate/logout`;
  splunkReporter.contextual({
    logInfo: { logLevel: 'info', logger },
    event: 'signOut',
    action: 'clicked',
    Refactored: 'true',
  });

  const data = { url: endpoint, endpoint, data: { event: 'signOut', ssrtid, token } };
  await httpClient.delete(endpoint, data);

  setTimeout(() => {
    window.location.reload();
  }, 0);
};

export const refreshAuthBL = async (args: {
  portal: string;
  ssrtid: string;
  authToken: string;
  token: string;
}) => {
  const { ssrtid, portal, authToken, token } = args;
  const endpoint = `/${portal}/rest/authenticate/refresh-auth`;
  splunkReporter.contextual({
    logInfo: { logLevel: 'info', logger },
    event: 'refreshAuth',
    action: 'begin',
  });
  const r = await httpClient.get(endpoint, {
    responseType: 'json',
    endpoint,
    headers: {
      Authorization: `Bearer ${authToken}`,
      'ssr-session-id': ssrtid,
      'intuit-acstoken': token,
    },
  });
  return r.data;
};

export const isFrictionlessLoginEligibleBL = ({
  frictionlessLoginEmail,
  frictionlessLoginSessionId,
  featureFlags,
  sale,
}: {
  frictionlessLoginEmail?: string;
  frictionlessLoginSessionId?: string;
  featureFlags: FeatureFlags;
  sale: Pick<Sale, 'type'>;
}) => {
  if (!sale) {
    return false;
  }

  const isSubscription = sale.type === 'SUBSCRIPTION';
  const featureFlag = isSubscription
    ? 'SBSEG-CP-cp-subsction-frictionless-login-enabled'
    : 'SBSEG-CP-cp-frictionless-login-enabled';

  if (!featureFlags[featureFlag]) {
    return false;
  }
  if (!frictionlessLoginEmail) {
    return false;
  }
  if (!frictionlessLoginSessionId) {
    return false;
  }
  return true;
};

/**
 * If frictionless login modal should be shown
 */
export const isFrictionlessLoginEnabledBL = async ({
  isUserSignedIn,
  frictionlessLoginEmail,
  frictionlessLoginSessionId,
  featureFlags,
  sale,
}: {
  isUserSignedIn: boolean;
  frictionlessLoginEmail?: string;
  frictionlessLoginSessionId?: string;
  featureFlags: FeatureFlags;
  sale: Pick<Sale, 'type'>;
}) => {
  if (
    !isFrictionlessLoginEligibleBL({
      frictionlessLoginEmail,
      frictionlessLoginSessionId,
      featureFlags,
      sale,
    })
  ) {
    return false;
  }
  if (isUserSignedIn) {
    return false;
  }
  return true;
};

export const shouldRaiseAAL = (error: AxiosError): boolean => {
  try {
    const remediationToken = error?.response?.headers?.['intuit_remediations'];
    if (!remediationToken) {
      return false;
    }

    const parsedRemediationToken: { id: string; type: string }[] = JSON.parse(
      atob(remediationToken)
    );
    const requiredRemediationKey = 'irn:intuit::identity:remediation/session-has-verified-phone';
    const requiredRemediation = parsedRemediationToken.find(
      ({ id }) => id === requiredRemediationKey
    );
    return !!requiredRemediation;
  } catch (error) {
    splunkReporter.contextual({
      logInfo: { logLevel: 'error', logger },
      event: 'parseRemediationToken',
      action: 'frictionlessLogin',
      error,
    });
    return false;
  }
};
