const {
  isMobile,
  isWebkit,
  isIOSDevice,
  getOSName,
  getBrowserName,
} = require('server/helpers/deviceInfo');
import { saleSelectors } from 'store/sale/selectors';
const Logger = require('reporting/Logger');
const { SplunkReporter } = require('reporting/splunk/SplunkReporter');
const logger = 'shared/clientUtils';
const splunkReporter = SplunkReporter.getInstance();

const DEFAULT_PAYMENT_OPTION_ORDER = [
  'dc,cc',
  'dc',
  'cc',
  'bank',
  'ap',
  'paypal_ppaam',
  'paypal_ppaam paylater',
  'venmo',
  'nanopay',
  'eft',
  'paypal',
  'razorpay',
  'pbb',
];

const getEnabledPaymentMethod = (paymentMethods) => {
  if (paymentMethods) {
    return (paymentMethods || []).filter((po) => DEFAULT_PAYMENT_OPTION_ORDER.indexOf(po) > -1);
  }
};
const getPaymentMethodsOrder = ({ enabledPaymentMethods }) => {
  let order = DEFAULT_PAYMENT_OPTION_ORDER;
  return order.filter((paymentMethod) => (enabledPaymentMethods || []).indexOf(paymentMethod) > -1);
};

/**
 * waits time in milliseconds via promise
 * @param {number} timeInMS
 * @return { Promise<void> }
 */
export const wait = async (timeInMS) =>
  new Promise((resolve) => setTimeout(() => resolve(), timeInMS));

/**
 * util to help create a performance measure in browser
 * if used in node.js or a test; is a no-op with a consistant api
 * @param {string} tag
 */
export function mark(tag) {
  if (window && window.performance && window.performance.mark) {
    let startMarkName = `start-${tag}`;
    window.performance.mark(startMarkName);
    return {
      startMarkName: `start-${tag}`,
      endMarkName: `end-${tag}`,
      durationMarkName: `duration-${tag}`,
      finish: function () {
        if (window && window.performance && window.performance.mark) {
          window.performance.mark(this.endMarkName);
          if (window.performance.measure) {
            window.performance.measure(this.durationMarkName, startMarkName, this.endMarkName);
          }
        }
      },
    };
  } else {
    // no-op
    return { finish: () => {} };
  }
}

export const getWeakTicket = ({
  domainId,
  entityId,
  realmId,
  token,
  ticket,
  isUserSignedIn,
  syncToken,
  authToken,
  ssrtid,
}) => {
  /**
   * @type {import('axios').RawAxiosRequestHeaders}
   */
  const headers = {
    Accept: 'application/json, text/javascript, */*; q=0.01',
    'Intuit-DomainId': domainId,
    'Intuit-IntuitId': entityId,
    'Intuit-RealmId': realmId,
    'Intuit-ACSToken': token,
    sessionTicket: ticket,
    'user-signed-in': typeof isUserSignedIn === 'boolean' ? isUserSignedIn.toString() : 'false',
    syncToken: syncToken,
    Authorization: `Bearer ${authToken}`,
    'ssr-session-id': ssrtid,
  };
  return headers;
};

const scsPaymentEnabled = ({ paymentMethodType } = {}) => {
  switch (paymentMethodType) {
    case 'bank':
    case 'cc':
    case 'dc':
    case 'dc,cc':
    case 'eft':
    case 'ap':
    case 'nanopay':
      return true;
    default:
      return false;
  }
};

const B2B_ERROR_CODES = {
  SKIP_FLOW: 'cp-b2b-1001',
};

const getWalletAccount = (wallet, selectedId) => {
  const walletAccount = wallet.find((walletAccount) => walletAccount.id === selectedId);
  if (!walletAccount) {
    throw Error(`Wallet entry with id: ${selectedId} doesn't exist`);
  }
  return walletAccount;
};

const capitalizeFirstLetter = (string) => {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
};

const getOneToManyEstimatedDelivery = (str) => {
  const options = {
    BUSINESS_DAYS_1_3: '1-3',
    BUSINESS_DAYS_3_5: '3-5',
    BUSINESS_DAYS_5_10: '5-10',
    BUSINESS_DAYS_10_20: '10-20',
    BUSINESS_DAYS_20_PLUS: '20+',
  };
  return `${options[str]} business days after payment.`;
};

/**
 * @param {*} paymentMethodType
 * @param {*} payPalProcessor
 * @param {*} featureFlags
 * @returns boolean
 */
export const isPayPalAppConnectBlock = (paymentMethodType, payPalProcessor, featureFlags) => {
  const isPaypalAppConnect = paymentMethodType === 'pp' && payPalProcessor === 'APPCONNECT';
  const shouldBlock = featureFlags && featureFlags['block-payments-paypal-appconnect'] === true;
  const dueTime = featureFlags && featureFlags['block-payments-paypal-appconnect-due-date'] > 0;
  return isPaypalAppConnect && (shouldBlock || dueTime);
};

/**
 * @param {*} paymentMethodType
 * @param {*} featureFlags
 * @returns boolean
 */
export const isNanoPayBankBlock = (paymentMethodType, featureFlags) => {
  const isNanoPay = paymentMethodType === 'nanopay';
  return isNanoPay && getDueDate(featureFlags, 'block-payments-nanopay-due-date') > 0;
};

/**
 * @param {*} featureFlags
 * @returns Number - Nano pay blocking due date (UTC).
 */
export const getNanopayBlockDueDate = (featureFlags) => {
  return getDueDate(featureFlags, 'block-payments-nanopay-due-date');
};

/**
 * Return the blocking Paypal due date - which is represented by a UTC number
 * for example : 1708167600000. If Paypal should no be blocked - return 0.
 * @param {*} featureFlags
 * @returns
 */
export const getPayPalAppConnectBlockDueDate = (featureFlags) => {
  return getDueDate(featureFlags, 'block-payments-paypal-appconnect-due-date');
};

/**
 * @param {*} date - UTC date as a number. for example : 1708167600000.
 * @returns locale time as string : for example : 1:00 pm
 */
export const gettoLocaleTimeString = (date) => {
  const localeTimeString = new Date(date).toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
  });
  return localeTimeString;
};

/**
 * @param {*} featureFlags
 * @param {*} key
 * @returns
 */
const getDueDate = (featureFlags, key) => {
  const dueDate = featureFlags?.[key];
  return dueDate > 0 ? dueDate : 0;
};

const getCookieByName = (key) => {
  try {
    const regex = new RegExp('(^| )' + key + '=([^;]+)');
    const match = typeof document === 'object' && document.cookie.match(regex);
    return match ? match[2] : '';
  } catch (e) {
    return '';
  }
};
const getPaymentStatusFromResponse = (res) => {
  if (res && res.data && res.data.paymentStatus) {
    return res.data.paymentStatus;
  }
  return null;
};
export const getInitialSelectedPaymentMethod = ({
  enabledPaymentOptions = [],
  userAgent,
  isUserSignedIn,
}) => {
  // We will not set initial selected payment method for mobile guest users
  const shouldHaveNoInitialSelectedPaymentMethod = () => {
    const isMobileDevice = isMobile(userAgent);
    const isGuest = !isUserSignedIn;
    const hasMoreThanOnePaymentMethodEnabled = enabledPaymentOptions.length > 1;
    // For ppaam there might be situation where due to device/location we will not be able to show the payment method
    const mightShowLessThanTwoPaymentMethods =
      enabledPaymentOptions.includes('paypal_ppaam') && enabledPaymentOptions.length < 4;
    return (
      isMobileDevice &&
      isGuest &&
      hasMoreThanOnePaymentMethodEnabled &&
      !mightShowLessThanTwoPaymentMethods
    );
  };
  let initialSelectedPaymentMethod = null;
  // If this is a mobile device, and we have more than one payment method, set the initiall selected pm to null
  if (shouldHaveNoInitialSelectedPaymentMethod()) {
    initialSelectedPaymentMethod = null;
  } else if (enabledPaymentOptions.indexOf('dc') > -1) {
    initialSelectedPaymentMethod = 'dc';
  } else if (enabledPaymentOptions.indexOf('cc') > -1) {
    initialSelectedPaymentMethod = 'cc';
  } else if (enabledPaymentOptions.indexOf('bank') > -1) {
    initialSelectedPaymentMethod = 'bank';
  } else if (enabledPaymentOptions.indexOf('paypal_ppaam') > -1) {
    initialSelectedPaymentMethod = 'paypal_ppaam';
  } else if (enabledPaymentOptions.indexOf('venmo') > -1) {
    initialSelectedPaymentMethod = 'venmo';
  } else if (enabledPaymentOptions.indexOf('nanopay') > -1) {
    initialSelectedPaymentMethod = 'nanopay';
  } else if (enabledPaymentOptions.indexOf('paypal') > -1) {
    initialSelectedPaymentMethod = 'pp';
  } else if (enabledPaymentOptions.indexOf('eft') > -1) {
    initialSelectedPaymentMethod = 'eft';
  } else if (enabledPaymentOptions.indexOf('pbb') > -1) {
    initialSelectedPaymentMethod = 'pbb';
  }
  return initialSelectedPaymentMethod;
};

const convertToNullIfUndefined = (prop) => (prop === undefined ? null : prop);

export const getLegalLinks = (locale) => {
  const defaultLinks = {
    tosUrl: '/payor-terms-of-service',
    privacyUrl: 'https://security.intuit.com/index.php/privacy',
    padUrl:
      'https://www.intuit.com/legal/terms/en-global/quickbooks/merchant-payment/#CA-payor-terms',
    caToSUrl: 'https://www.intuit.com/legal/terms/en-ca/quickbooks/online/',
    intuitAccountUrl: 'https://accounts-help.intuit.com/app/intuit/1995107',
    termsUrl: 'https://connect.intuit.com/payor-terms-of-service',
    privacyStatementUrl: 'https://www.intuit.com/privacy/statement/',
  };

  const legalLinks = {
    en: defaultLinks,
    fr: {
      tosUrl: 'https://quickbooks.intuit.com/fr-ca/paiements/documents-legaux/conditions-du-payeur',
      privacyUrl: 'https://www.intuit.com/privacy/statement/fr-ca/',
      padUrl:
        'https://www.intuit.com/legal/terms/en-global/quickbooks/merchant-payment/#CA-payor-terms',
      caToSUrl: 'https://www.intuit.com/legal/terms/fr-ca/quickbooks/online/',
    },
  };
  return legalLinks[locale] || defaultLinks;
};
export const formatISO8601Date = (dueDate) => {
  if (typeof dueDate === 'string') {
    try {
      let [first, second, third] = dueDate.split('-').map((x) => parseInt(x, 10));
      if (
        !/^\d{2,4}-\d{2}-\d{2,4}$/.test(dueDate) ||
        ![first, second, third].every((piece) => Number.isInteger(piece))
      ) {
        return false;
      }
      let output = new Date();
      output.setUTCHours(0);
      output.setUTCMinutes(0);
      output.setUTCSeconds(0);
      output.setUTCMilliseconds(0);
      if (first > third) {
        output.setUTCFullYear(first, second - 1, third);
      } else {
        output.setUTCFullYear(third, first - 1, second);
      }
      let currentYear = new Date().getUTCFullYear();
      let outputYear = output.getUTCFullYear();
      if (outputYear > currentYear + 10 || outputYear < currentYear - 10) {
        return false;
      }

      return output;
    } catch (error) {
      return false;
    }
  }
  return false;
};

const loadGdpr = (state = {}) => {
  const {
    config: {
      endpoints,
      localeConf: { shouldEnableGdpr },
    },
  } = state;
  if (shouldEnableGdpr === true) {
    const { gdprUtilBundle, gdprWidget } = endpoints;
    let gdprScripts = [gdprUtilBundle, gdprWidget];
    window.intuit_gdpr = {
      id: 'gdprComponent',
      domains: [],
      onComplete: () => {},
      onFail: () => (error) => {
        splunkReporter.contextual({
          logInfo: { logLevel: 'error', logger },
          event: 'viewSale',
          action: 'gdpr',
          activityInfo: {
            status: 'error',
          },
          error: {
            message: error?.message,
            stack: error?.stack,
          },
        });
      },
    };
    gdprScripts.forEach(function (element) {
      const script = document.createElement('script');
      script.src = element;
      script.async = true;
      document.body.appendChild(script);
    });
  }
};

const initLogger = (initialState = {}) => {
  splunkReporter.setLogger(Logger);
  const {
    config: { cpVersion, ssrtid, dockerTag, originatingIp, fullUrl } = {},
    auth: { realmId, isUserSignedIn } = {},
    insight: {
      token,
      domainId,
      merchantId,
      riskProfileToken: riskProfileToken,
      appSourceOffering,
      isPayEnabled,
    } = {},
    sale: { type, subType, id: saleId, contact: { toEmails = [] } = {} } = {},
    companyInfo: { sourceOffering, region } = {},
    ixp = {},
  } = initialState;
  splunkReporter.setIsEnabled(true);
  const userAgent = typeof window === 'object' && window.navigator.userAgent;
  const deviceInfo = {
    userAgent: userAgent,
    browserName: getBrowserName(userAgent),
    osName: getOSName(userAgent),
    isMobileDevice: isMobile(userAgent),
    isIOSDevice: isIOSDevice(userAgent),
    isWebkit: isWebkit(userAgent),
  };
  splunkReporter.setClientSession({
    sessionId: ssrtid,
    token,
    saleId,
    offeringId: sourceOffering,
    saleType: type,
    saleSubType: subType,
    domainId,
    country: region,
    originatingIp,
    ixp,
    merchantId,
    realmId,
    deviceInfo,
    riskProfileToken,
    isUserSignedIn,
    appSourceOffering,
    isPayEnabled,
    fullUrl,
    hasEmail: toEmails && toEmails.length > 0, // false if array is empty or invalid
    achOnlineConvenienceFeeEnable: saleSelectors.achOnlineConvenienceFeeEnabledSelector(
      initialState?.sale
    ),
    achOnlineConvenienceFeeAmount: saleSelectors.achOnlineConvenienceFeeAmountSelector(
      initialState?.sale
    ),
  });

  splunkReporter.setAppImage(dockerTag);
  splunkReporter.setAppVersion(cpVersion);
};

export const formatPaymentMethod = (paymentMethod) => {
  let formattedPaymentMethod = paymentMethod;
  switch (formattedPaymentMethod) {
    case 'bank':
    case 'BANK_ACCOUNT':
      formattedPaymentMethod = 'bank';
      break;
    case 'CREDIT_CARD':
    case 'credit card':
    case 'cc':
    case 'dc':
      formattedPaymentMethod = 'card';
      break;
    case 'credit card wallet':
      formattedPaymentMethod = 'card wallet';
      break;
    case 'BANK_ACCOUNT_WALLET':
      formattedPaymentMethod = 'bank wallet';
      break;
    case 'APPLE_PAY':
      formattedPaymentMethod = 'applePay';
      break;
    case 'Venmo':
      formattedPaymentMethod = 'venmo';
      break;
  }
  return formattedPaymentMethod;
};
const filterRequestToken = (url, token) => url?.replace(token, '{token}');
const filterRequestQueryParams = (url) => url?.split('?')[0];

module.exports = {
  wait,
  mark,
  DEFAULT_PAYMENT_OPTION_ORDER,
  B2B_ERROR_CODES,
  getPaymentMethodsOrder,
  getEnabledPaymentMethod,
  getWeakTicket,
  scsPaymentEnabled,
  getWalletAccount,
  capitalizeFirstLetter,
  getOneToManyEstimatedDelivery,
  isPayPalAppConnectBlock,
  getCookieByName,
  getPaymentStatusFromResponse,
  getInitialSelectedPaymentMethod,
  convertToNullIfUndefined,
  getLegalLinks,
  formatISO8601Date,
  loadGdpr,
  initLogger,
  formatPaymentMethod,
  filterRequestToken,
  filterRequestQueryParams,
  getPayPalAppConnectBlockDueDate,
  isNanoPayBankBlock,
  gettoLocaleTimeString,
  getNanopayBlockDueDate,
};
