import Debug from 'debug';

import type { AxiosError, AxiosResponse, AxiosPromise } from 'axios';
// @ts-ignore
// eslint-disable-next-line @typescript-eslint/no-unused-vars  -- strange flakiness on CI without this import
import type { AxiosRetry } from 'axios-retry';
import type { Response } from 'express';

import Profiler from 'reporting/Profiler';

const profiler = Profiler.getInstance();
// @ts-ignore
const networkProfiler = profiler.assignProfiling('network');
const debug = Debug('ssr:middleware:httpInterceptors');

type CPResponse = {
  config: {
    ignoreProfilingInterceptor: boolean;
    ignoreErrorInterceptor: boolean;
    ssrtid: string;
    endpoint: string;
    userAgent: string;
    token: string;
    xForwardedFor: string;
  };
} & AxiosResponse;
type IResponseErrorInterceptorError = AxiosError & CPResponse;

type Interceptor = (response: CPResponse) => AxiosPromise<CPResponse>;
type ErrorInterceptor = (response: IResponseErrorInterceptorError) => AxiosPromise<CPResponse>;
/**
 * Logs network profile
 */
export const responseProfilerInterceptor: Interceptor = (response: CPResponse) => {
  const {
    config: { ignoreProfilingInterceptor, endpoint, url },
  } = response;
  debug(
    `responseProfilerInterceptor, endpoint=${endpoint}, url=${url}, ignoreProfilingInterceptor=${!!ignoreProfilingInterceptor}`
  );
  // @ts-ignore
  !ignoreProfilingInterceptor && networkProfiler(response);
  return Promise.resolve(response);
};

/*
  Mutates the res with extra headers
 */
export const setExtraHeadersForResponse = (
  res: Response,
  headers: Record<string, string | number> = {}
): void => {
  if (!Object.keys(headers).length) {
    return;
  }

  Object.entries(headers).forEach(([key, value]) => {
    res.setHeader(key, value);
  });
};

export const responseErrorInterceptor: ErrorInterceptor = (
  errorResponse: IResponseErrorInterceptorError
) => {
  const error = Object.assign({}, errorResponse);

  const axiosRetryConfig = error.config?.['axios-retry'];

  // clone deep axios-retry object
  if (axiosRetryConfig) {
    error.config = Object.assign({}, error.config);
    error.config['axios-retry'] = Object.assign({}, error.config['axios-retry']);
  }

  setTimeout(() => {
    debug(
      `responseErrorInterceptor, hasOwnProperty('response')=${error.hasOwnProperty(
        'response'
      )}, hasOwnProperty('config')=${error.hasOwnProperty('config')}`
    );

    if (!error.hasOwnProperty('response') && !error.hasOwnProperty('config')) {
      // execution errors
    } else {
      // network errors

      const {
        config: { url, endpoint, headers, ignoreErrorInterceptor },
        response,
      } = error;
      if (ignoreErrorInterceptor) {
        debug(`ignoring endpoint=${endpoint}, url=${url}`);
        return;
      }

      if (headers && !response) {
        error.status = 408;
      } else {
        // @ts-ignore
        const { status } = response;
        error.status = status;
      }
      // @ts-ignore
      networkProfiler(error);
    }
  }, 0);

  return Promise.reject(errorResponse);
};
