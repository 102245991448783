import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from 'store/store';

import { isFrictionlessLoginEligibleBL } from 'businessLogic/Auth/Auth';
import { emailRegex, usPhoneRegex } from 'shared/freeTextModalValidators';

export type AuthSelectorState = Pick<RootState, 'auth'>;

export const authTicketSelector = (store: AuthSelectorState): string | undefined => {
  return store.auth.ticket;
};

export const isFrictionlessLoginEnabledSelector = (store: AuthSelectorState): boolean => {
  return store.auth.isFrictionlessLoginEnabled;
};

export const isFrictionlessLoginEligibleSelector = (
  store: Pick<RootState, 'auth' | 'featureFlags' | 'sale'>
): boolean => {
  return isFrictionlessLoginEligibleBL({
    frictionlessLoginEmail: store.auth.frictionlessLoginEmail,
    frictionlessLoginSessionId: store.auth.frictionlessLoginSessionId,
    featureFlags: store.featureFlags,
    sale: store.sale,
  });
};

export const frictionlessLoginEmailSelector = (store: AuthSelectorState): string | undefined => {
  return store.auth.frictionlessLoginEmail;
};

export const frictionlessLoginSessionIdSelector = (
  store: AuthSelectorState
): string | undefined => {
  return store.auth.frictionlessLoginSessionId;
};

export const frictionlessCheckoutSelector = (store: AuthSelectorState): object => {
  return store.auth?.frictionlessCheckout;
};

export const isFrictionlessCheckoutSelectedSelector = (store: AuthSelectorState): boolean => {
  return store.auth?.frictionlessCheckout?.isSelected;
};

export const isUserClosedFrictionlessOTPSelector = (store: AuthSelectorState): boolean => {
  return store.auth?.isUserClosedFrictionlessOTP;
};

export const isFrictionlessCheckoutDetailsRequiredSelector = (
  store: AuthSelectorState
): boolean => {
  return store.auth?.frictionlessCheckout?.isDetailsRequired;
};

export const frictionlessCheckoutEmailSelector = (store: AuthSelectorState): string | undefined => {
  return store.auth?.frictionlessCheckout?.email;
};

export const isFrictionlessCheckoutEmailValidSelector = createSelector(
  frictionlessCheckoutEmailSelector,
  (email) => Boolean(email && emailRegex.test(email))
);

export const frictionlessCheckoutPhoneSelector = (store: AuthSelectorState): string | undefined => {
  return store.auth?.frictionlessCheckout?.phone;
};

export const isFrictionlessCheckoutPhoneValidSelector = createSelector(
  frictionlessCheckoutPhoneSelector,
  (phone) => Boolean(phone && usPhoneRegex.test(phone))
);

export const isFrictionlessCheckoutDataInvalid = createSelector(
  isFrictionlessCheckoutSelectedSelector,
  isFrictionlessCheckoutPhoneValidSelector,
  isFrictionlessCheckoutEmailValidSelector,
  (isSelected, isPhoneValid, isEmailValid) => {
    return isSelected && (!isPhoneValid || !isEmailValid);
  }
);
