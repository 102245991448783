import { PayloadAction } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

import {
  doSendCapabilityPayload,
  getBankAccounts,
  getToken,
  initializeNanopayOnboarding,
  logInfoNanopayFlow,
  nanopayOnboardingRequestStatus,
} from './nanopay';

import BankTransferCAGuest from 'components/Core/Nanopay/BankTransfer/BankTransferCAGuest';
import Logger from 'reporting/Logger';
import { modalActions } from 'store/modal/slice';
import { RootState } from 'store/store';
import { sliceFactory, thunkActionFactory } from 'store/utils';
import { Nanopay } from 'types/Nanopay';
import { Sale } from 'types/Sale';

const isFlinksFlowMocked = (): boolean => {
  if (typeof window === 'undefined') {
    return false;
  }
  if (
    !window.location?.host?.includes('localhost') &&
    !window.location?.host?.includes('e2e') &&
    !window.location?.host?.includes('hotfix') &&
    !window.location?.host?.includes('release') &&
    !window.location?.host?.includes('pr-')
  ) {
    return false;
  }
  if (new URLSearchParams(window.location.search).get('mockFlinksFlow')) {
    return true;
  }
  return false;
};

export const initialState: Partial<Nanopay> = {
  // @ts-ignore
  payingWithAccount: {},
  caBankAccounts: [],
  retryState: true,
  validationErrors: {},
  /**
   * nanopay token service data
   */
  tokenLoading: false,
  tokenId: '',
  nanopayUrl: '',

  /**
   * the time delay in mseconds before new status check request is made
   */
  onboardingReqStatusCheckDelayInMS: 5000,

  /**
   * maximum number of calls made to check request status.
   * this will be useful on an average case when request status calls are returned successfully with in 60ms as example.
   */
  maxOnboardingReqStatusCheckCount: 24, // 25 * 5000 = 1,25,000 mseconds = 125 seconds

  /**
   * maximum amount of time thw number of calls will be allowed to check the request status.
   * when this time limit is reached -> then maxOnboardingReqStatusCheckCount value will not be honoured and error will be returned back on UI
   */
  maxOnboardingReqStatusCheckTimeoutInMS: 125000, // this time includes the API SLA time

  /**
   * boolean for e2e nanopay integration tests.
   * true: will mock Flinks login, skipping widget (mock used for playwright integration testing only)
   * false: will render normal Flinks widget login
   */
  mockFlinksFlow:
    // @ts-ignore
    isFlinksFlowMocked() ? true : false,
};

const { reducer, actions } = sliceFactory({
  name: 'nanopay',
  initialState,
  reducers: {
    nanopayUpdateErrorState(state, action: PayloadAction<Pick<Nanopay, 'error'>>) {
      state.error = action.payload.error;
    },
    nanopayUpdateFlinksData(
      state,
      action: PayloadAction<
        Partial<
          Pick<
            Nanopay,
            'accountId' | 'institution' | 'loginId' | 'requestId' | 'hasFlinksFlowCompleted'
          >
        >
      >
    ) {
      const { accountId, institution, loginId, requestId, hasFlinksFlowCompleted } = action.payload;
      return {
        ...state,
        accountId: accountId || state.accountId || '',
        institution: institution || state.institution || '',
        loginId: loginId || state.loginId || '',
        requestId: requestId || state.requestId || '',
        hasFlinksFlowCompleted,
      };
    },
    nanopayUpdatePayingWithAccount(state, action: PayloadAction<{ payingWithAccountId: string }>) {
      const { payingWithAccountId } = action.payload;
      if (state.caBankAccounts) {
        const payingWithAccount = state.caBankAccounts.find(
          (bank) => bank.id === payingWithAccountId
        );
        state.payingWithAccount = payingWithAccount;
      }
    },
    nanopayUpdateCABankAccounts(state, action: PayloadAction<Pick<Nanopay, 'caBankAccounts'>>) {
      state.caBankAccounts = action.payload.caBankAccounts;
    },
    nanopayUpdateNanopayConnectStatus(
      state,
      action: PayloadAction<Pick<Nanopay, 'hasNanopayConnected'>>
    ) {
      state.hasNanopayConnected = action.payload.hasNanopayConnected;
    },
    nanopayUpdateNanopayIds(state, action: PayloadAction<Pick<Nanopay, 'userId' | 'businessId'>>) {
      const { userId, businessId } = action.payload;
      state.userId = userId;
      state.businessId = businessId;
    },
    nanopayUpdateCapabilityId(state, action: PayloadAction<Pick<Nanopay, 'capabilityId'>>) {
      state.capabilityId = action.payload.capabilityId;
    },
    nanopayUpdateNanopayAccount(state, action: PayloadAction<Pick<Nanopay, 'nanopayAccount'>>) {
      state.nanopayAccount = action.payload.nanopayAccount;
    },
    nanopayUpdateUserBearerToken(state, action: PayloadAction<Pick<Nanopay, 'userBearerToken'>>) {
      state.userBearerToken = action.payload.userBearerToken;
    },
    nanopayUpdateIsLoading(state, action: PayloadAction<Pick<Nanopay, 'isLoading'>>) {
      state.isLoading = action.payload.isLoading;
    },
    nanopayShouldShowExtraInfo(state, action: PayloadAction<Pick<Nanopay, 'shouldShowExtraInfo'>>) {
      state.shouldShowExtraInfo = action.payload.shouldShowExtraInfo;
    },
    nanopaySetBusiness(state, action: PayloadAction<Pick<Nanopay, 'isBusiness'>>) {
      state.isBusiness = action.payload.isBusiness;
    },
    updateShowErrorCard(state, action: PayloadAction<Pick<Nanopay, 'showErrorCard'>>) {
      state.showErrorCard = action.payload.showErrorCard;
    },
    setRetryState(state, action: PayloadAction<Pick<Nanopay, 'retryState'>>) {
      state.retryState = action.payload.retryState;
    },
    nanopayCaptureOnboardingRequestData(
      state,
      action: PayloadAction<Pick<Nanopay, 'onboardingRequestId'>>
    ) {
      state.onboardingRequestId = action.payload.onboardingRequestId;
    },
    nanopayValidationErrors(state, action: PayloadAction<Pick<Nanopay, 'validationErrors'>>) {
      state.validationErrors = action.payload.validationErrors;
    },
    nanopayTokenServiceInProgress(state, action: PayloadAction<Pick<Nanopay, 'tokenLoading'>>) {
      state.tokenLoading = action.payload.tokenLoading;
    },
    nanopayTokenServiceData(state, action: PayloadAction<Pick<Nanopay, 'tokenId' | 'nanopayUrl'>>) {
      const { tokenId, nanopayUrl } = action.payload;
      state.tokenId = tokenId;
      state.nanopayUrl = nanopayUrl;
    },
    nanopayTokenServiceDataError(state) {
      state.showErrorCard = true;
    },
  },
});

export const nanopayReducer = reducer;
export const nanopayActions = actions;

export const logNanopayError = thunkActionFactory<{ message: string }>(
  ({ payload, dispatch, state }) => {
    const {
      config: { ssrtid },
    } = state;
    Logger.error({
      EventName: 'nanopay',
      EventMessage: payload?.message,
      ssrtid,
    });

    dispatch(
      nanopayActions.nanopayUpdateErrorState({
        error: payload?.message || 'Error occurred in Nanopay',
      })
    );
  }
);

export const updateNanopayErrorState = thunkActionFactory<Pick<Nanopay, 'error'>>(
  ({ payload, dispatch, state }) => {
    const {
      config: { ssrtid },
    } = state;
    if (payload) {
      dispatch(nanopayActions.nanopayUpdateErrorState(payload));
    }
    logInfoNanopayFlow({ ssrtid, action: 'updateNanopayErrorState', opts: { payload } });
  }
);

export const updateFlinksData = thunkActionFactory<
  Partial<
    Pick<Nanopay, 'accountId' | 'institution' | 'loginId' | 'requestId' | 'hasFlinksFlowCompleted'>
  >
>(({ payload, dispatch, state }) => {
  const { accountId, institution, loginId, requestId, hasFlinksFlowCompleted } = payload || {};
  const {
    config: { ssrtid },
  } = state;
  dispatch(
    nanopayActions.nanopayUpdateFlinksData({
      accountId,
      institution,
      loginId,
      requestId,
      hasFlinksFlowCompleted,
    })
  );
  logInfoNanopayFlow({
    ssrtid,
    action: 'updateFlinksData',
    state: hasFlinksFlowCompleted ? 'completed' : 'partial',
    opts: { ...payload },
  });
});

export const updatePayingWithCAAccount = thunkActionFactory<{ payingWithAccountId: string }>(
  ({ payload, dispatch, state }) => {
    const {
      config: { ssrtid },
    } = state;
    if (payload) {
      dispatch(
        nanopayActions.nanopayUpdatePayingWithAccount({
          payingWithAccountId: payload.payingWithAccountId,
        })
      );
    }
    logInfoNanopayFlow({ ssrtid, action: 'updatePayingWithCAAccount', opts: { ...payload } });
  }
);

export const updateCABankAccounts = thunkActionFactory<Pick<Nanopay, 'caBankAccounts'>>(
  ({ payload, dispatch, state }) => {
    const {
      config: { ssrtid },
    } = state;
    if (payload) {
      dispatch(
        nanopayActions.nanopayUpdateCABankAccounts({
          caBankAccounts: payload.caBankAccounts,
        })
      );
    }
    logInfoNanopayFlow({ ssrtid, action: 'updateCABankAccounts', opts: { ...payload } });
  }
);

export const updateNanopayConnectStatus = thunkActionFactory<Pick<Nanopay, 'hasNanopayConnected'>>(
  ({ payload, dispatch, state }) => {
    const {
      config: { ssrtid },
    } = state;
    const { hasNanopayConnected } = payload || {};
    dispatch(
      nanopayActions.nanopayUpdateNanopayConnectStatus({
        hasNanopayConnected: Boolean(hasNanopayConnected),
      })
    );
    logInfoNanopayFlow({ ssrtid, action: 'updateNanopayConnectStatus', opts: { ...payload } });
  }
);

export const updateNanopayIds = thunkActionFactory<Pick<Nanopay, 'userId' | 'businessId'>>(
  ({ payload, dispatch, state }) => {
    const {
      config: { ssrtid },
    } = state;
    if (payload) {
      dispatch(
        nanopayActions.nanopayUpdateNanopayIds({
          userId: payload.userId,
          businessId: payload.businessId,
        })
      );
    }
    logInfoNanopayFlow({ ssrtid, action: 'updateNanopayIds', opts: { ...payload } });
  }
);

export const updateCapabilityId = thunkActionFactory<Pick<Nanopay, 'capabilityId'>>(
  ({ payload, dispatch, state }) => {
    const {
      config: { ssrtid },
    } = state;
    if (payload) {
      dispatch(
        nanopayActions.nanopayUpdateCapabilityId({
          capabilityId: payload.capabilityId,
        })
      );
    }
    logInfoNanopayFlow({ ssrtid, action: 'updateCapabilityId', opts: { ...payload } });
  }
);

export const updateNanopayAccount = thunkActionFactory<Pick<Nanopay, 'nanopayAccount'>>(
  ({ payload, dispatch, state }) => {
    const {
      config: { ssrtid },
    } = state;
    if (payload) {
      dispatch(
        nanopayActions.nanopayUpdateNanopayAccount({
          nanopayAccount: payload.nanopayAccount,
        })
      );
    }
    logInfoNanopayFlow({ ssrtid, action: 'updateNanopayAccount', opts: { ...payload } });
  }
);

export const updateNanopayUserBearerToken = thunkActionFactory<Pick<Nanopay, 'userBearerToken'>>(
  ({ payload, dispatch, state }) => {
    const {
      config: { ssrtid },
    } = state;
    if (payload) {
      dispatch(
        nanopayActions.nanopayUpdateUserBearerToken({
          userBearerToken: payload.userBearerToken,
        })
      );
    }
    logInfoNanopayFlow({ ssrtid, action: 'updateNanopayUserBearerToken' });
  }
);

export const updateNanopayIsLoading = thunkActionFactory<Pick<Nanopay, 'isLoading'>>(
  ({ payload, dispatch, state }) => {
    const {
      config: { ssrtid },
    } = state;
    if (payload) {
      dispatch(
        nanopayActions.nanopayUpdateIsLoading({
          isLoading: payload.isLoading,
        })
      );
    }
    logInfoNanopayFlow({ ssrtid, action: 'updateNanopayIsLoading', opts: { ...payload } });
  }
);

export const updateNanopayShouldShowExtraInfo = thunkActionFactory<
  Pick<Nanopay, 'shouldShowExtraInfo'>
>(({ payload, dispatch, state }) => {
  const {
    config: { ssrtid },
  } = state;
  if (payload) {
    dispatch(
      nanopayActions.nanopayShouldShowExtraInfo({
        shouldShowExtraInfo: payload.shouldShowExtraInfo,
      })
    );
  }
  logInfoNanopayFlow({ ssrtid, action: 'updateNanopayShouldShowExtraInfo', opts: { ...payload } });
});

export const setBusiness = thunkActionFactory<any>(({ payload, dispatch }) => {
  dispatch(
    nanopayActions.nanopaySetBusiness({
      isBusiness: payload,
    })
  );
});

export const updateShowErrorCard = thunkActionFactory<Pick<Nanopay, 'showErrorCard'>>(
  ({ payload, dispatch, state }) => {
    const {
      config: { ssrtid },
    } = state;
    if (payload) {
      dispatch(
        nanopayActions.updateShowErrorCard({
          showErrorCard: payload.showErrorCard,
        })
      );
    }
    logInfoNanopayFlow({ ssrtid, action: 'updateShowErrorCard', opts: { ...payload } });
  }
);

export const setRetryState = thunkActionFactory<Pick<Nanopay, 'retryState'>>(
  ({ payload, dispatch, state }) => {
    const {
      config: { ssrtid },
    } = state;
    if (payload) {
      dispatch(
        nanopayActions.setRetryState({
          retryState: payload.retryState,
        })
      );
    }
    logInfoNanopayFlow({ ssrtid, action: 'setRetryState', opts: { ...payload } });
  }
);

export const captureOnboardingRequestData = thunkActionFactory<
  Pick<Nanopay, 'onboardingRequestId'>
>(({ payload, dispatch, state }) => {
  const {
    config: { ssrtid },
  } = state;
  if (payload) {
    dispatch(
      nanopayActions.nanopayCaptureOnboardingRequestData({
        onboardingRequestId: payload.onboardingRequestId,
      })
    );
  }
  logInfoNanopayFlow({ ssrtid, action: 'captureOnboardingRequestData', opts: { ...payload } });
});

export const updateValidationErrors = thunkActionFactory<Pick<Nanopay, 'validationErrors'>>(
  ({ payload, dispatch, state }) => {
    const {
      config: { ssrtid },
    } = state;
    if (payload) {
      dispatch(
        nanopayActions.nanopayValidationErrors({
          validationErrors: payload.validationErrors,
        })
      );
    }
    logInfoNanopayFlow({ ssrtid, action: 'updateValidationErrors', opts: { ...payload } });
  }
);

export const requestNanopayOnboarding = thunkActionFactory(async ({ dispatch, state }) => {
  const {
    config: { ssrtid },
  } = state;
  const { success: nanopayAccountRequested, onboardingRequestId } =
    await requestNanopayAccountAsync(dispatch, state);

  if (nanopayAccountRequested) {
    dispatch(captureOnboardingRequestData({ onboardingRequestId }));
  }
  logInfoNanopayFlow({
    ssrtid,
    action: 'requestNanopayOnboarding',
    opts: { nanopayAccountRequested },
  });
});

const requestNanopayAccountAsync = async (
  dispatch: Dispatch<any>,
  state: RootState & Partial<{ nanopay: any }>
) => {
  const {
    config: { ssrtid },
  } = state;
  let success = false;
  let onboardingRequestId = '';
  try {
    const { auth, config, payment, nanopay = {}, insight, sale } = state;

    const { paymentMethodType = '' } = payment || {};
    const { recipientEmail: userEmail } = auth;
    if (nanopay && nanopay.hasFlinksFlowCompleted && paymentMethodType === 'nanopay') {
      const response = await initializeNanopayOnboarding(
        auth,
        config,
        insight,
        sale,
        nanopay,
        userEmail
      );

      if (response && response.requestId && response.status === 'In Progress') {
        onboardingRequestId = response.requestId;
        success = true;
      } else {
        throw new Error(
          `Error requesting async nanopay onboarding. Status: ${response.status} for email: ${userEmail} and requestId: ${response.requestId}`
        );
      }
    }
  } catch (error: any) {
    Logger.error({
      EventName: 'CPV2NanopayCommerceError',
      EventType: 'nanopay_onboarding_async',
      EventMessage: error.message,
      ssrtid,
    });
    dispatch(logNanopayError(error));
  }
  return { success, onboardingRequestId };
};

export const hasNanopayOnbardingCompleted = thunkActionFactory(async ({ dispatch, state }) => {
  const {
    config: { ssrtid },
  } = state;
  const { success: nanopayAccountCreated } = await getNanopayAccountStatus(dispatch, state);

  if (nanopayAccountCreated) {
    dispatch(updateNanopayConnectStatus({ hasNanopayConnected: true }));
  }
  logInfoNanopayFlow({
    ssrtid,
    action: 'hasNanopayOnbardingCompleted',
    opts: { nanopayAccountCreated, hasNanopayConnected: true },
  });
});

const getNanopayAccountStatus = async (dispatch: Dispatch<any>, state: RootState) => {
  const {
    config: { ssrtid },
  } = state;
  let success = false;

  try {
    // @ts-ignore
    const { auth, config, nanopay, insight } = state;

    if (nanopay && nanopay.hasFlinksFlowCompleted) {
      const response = await nanopayOnboardingRequestStatus(auth, config, insight, nanopay);

      if (response && response.status === 'Completed') {
        // Scan the onboarding completed response and onboard the payor to needed capabilities
        if (response.flinksLoginIdResult && response.flinksLoginIdResult.user) {
          const userId = response.flinksLoginIdResult.user || '';
          const account = response.flinksLoginIdResult.account || '';
          const businessId = response.flinksLoginIdResult.business || '';
          const capabilityId =
            (response.flinksLoginIdResult.capabilityPayloads &&
              response.flinksLoginIdResult.capabilityPayloads[0] &&
              response.flinksLoginIdResult.capabilityPayloads[0].id) ||
            '';
          if (userId && capabilityId) {
            dispatch(updateNanopayShouldShowExtraInfo({ shouldShowExtraInfo: true }));
            dispatch(
              updateValidationErrors({
                validationErrors:
                  response.flinksLoginIdResult.capabilityPayloads[0].capabilityValidationErrors,
              })
            );
            dispatch(updateNanopayAccount({ nanopayAccount: account }));
            dispatch(updateNanopayIds({ userId, businessId }));
            dispatch(updateCapabilityId({ capabilityId }));
            success = true; // mark operation as success when reaches this point
          }
        }
      } else if (response && response.status === 'In Progress') {
        success = false;
      } else {
        //  clear the requestId as it is neither 'Completed' nor in 'In Progress' status now
        dispatch(captureOnboardingRequestData({ onboardingRequestId: '' }));
        throw new Error(`Error onboarding user using async nanopay.`);
      }
    }
  } catch (error: any) {
    Logger.error({
      EventName: 'CPV2NanopayCommerceError',
      EventType: 'nanopay_onboarding_status',
      EventMessage: error.message,
      ssrtid,
    });
    dispatch(logNanopayError(error));
  }
  return { success };
};

export const findBankAccounts = thunkActionFactory(async ({ dispatch, state }) => {
  const {
    config: { ssrtid },
  } = state;
  dispatch(updateNanopayIsLoading({ isLoading: true }));

  let success = false;
  try {
    const { auth, config, nanopay, insight } = state;

    const { nanopayAccount: accountId, userBearerToken, ssrtid } = nanopay || {};

    if (accountId && userBearerToken) {
      const response = await getBankAccounts(
        auth,
        config,
        insight,
        accountId,
        userBearerToken,
        ssrtid as string
      );

      if (response && response.id) {
        dispatch(
          updateCABankAccounts({
            caBankAccounts: [
              { id: response.id, name: response.name, accountNumberMask: response.accountNumber },
            ],
          })
        );
        dispatch(updatePayingWithCAAccount({ payingWithAccountId: response.id })); // auto selects the bank account for payment
        success = true; // mark operation as success when reaches this point
      }
    }
  } catch (error: any) {
    Logger.error({
      EventName: 'CPV2NanopayCommerceError',
      EventType: 'nanopay_findBankAccounts',
      EventMessage: error.message,
      ssrtid,
    });
    dispatch(logNanopayError(error));
    dispatch(updateShowErrorCard({ showErrorCard: true }));
  }
  dispatch(updateNanopayIsLoading({ isLoading: false }));
  return success;
});

export const sendCapabilityPayloadAction = thunkActionFactory<any>(
  async ({ payload, dispatch, state }) => {
    const {
      config: { ssrtid },
    } = state;
    dispatch(updateNanopayIsLoading({ isLoading: true }));
    let success = false;
    try {
      const {
        firstName,
        lastName,
        birthday,
        phoneNumber,
        jobTitle,
        businessName,
        businessSectorId,
        holderName,
        accountNumber,
        transitNumber,
        institutionNumber,
        userAddress,
        businessAddress,
        businessMailingAddress,
        pepHIORelated,
      } = payload || {};

      const { auth, config, nanopay, insight } = state;

      const { accountId, businessId, userId, capabilityId } = nanopay || {};

      if (!userId) {
        throw new Error(
          `Nanopay userId is missing for accountId: ${accountId} and ssrtid: ${ssrtid}`
        );
      }

      const response = await doSendCapabilityPayload(auth, config, insight, {
        firstName,
        lastName,
        birthday,
        phoneNumber,
        jobTitle,
        businessName,
        businessSectorId,
        holderName,
        accountNumber,
        transitNumber,
        institutionNumber,
        userAddress,
        businessAddress,
        businessMailingAddress,
        pepHIORelated,
        businessId,
        userId,
        capabilityId,
        ssrtid,
      });

      if (response && response.id) {
        // no capabilityValidationErrors
        if (
          response.capabilityValidationErrors['Flinks Add Bank Accounts[holderName]'] ||
          response.capabilityValidationErrors['Flinks Add Bank Accounts[accountNumber]'] ||
          response.capabilityValidationErrors['Flinks Add Bank Accounts[branchId]'] ||
          response.capabilityValidationErrors['Flinks Add Bank Accounts[institutionNumber]'] ||
          response.capabilityValidationErrors['User Details[firstName]'] ||
          response.capabilityValidationErrors['User Details[lastName]'] ||
          response.capabilityValidationErrors['User Details[phoneNumber]'] ||
          response.capabilityValidationErrors['Expanded User Details[birthday]'] ||
          response.capabilityValidationErrors['Expanded User Details[jobTitle]'] ||
          response.capabilityValidationErrors['User Details[address]'] ||
          response.capabilityValidationErrors['Business Onboarding Details[businessName]'] ||
          response.capabilityValidationErrors['Business Onboarding Details[address]'] ||
          response.capabilityValidationErrors['Business Onboarding Details[mailingAddress]']
        ) {
          dispatch(updateNanopayIsLoading({ isLoading: false }));
        } else {
          dispatch(updateNanopayUserBearerToken({ userBearerToken: response.bearerToken }));
          dispatch(updateNanopayShouldShowExtraInfo({ shouldShowExtraInfo: false }));
          dispatch(updateNanopayIsLoading({ isLoading: false }));
          success = true; // mark operation as success when reaches this point
        }
      }
    } catch (error: any) {
      Logger.error({
        EventName: 'CPV2NanopayCommerceError',
        EventType: 'nanopay_sendCapabilityPayload',
        EventMessage: error.message,
        ssrtid,
      });
      dispatch(logNanopayError(error));
      dispatch(updateNanopayIsLoading({ isLoading: false }));
      dispatch(updateNanopayErrorState({ error: true }));
      dispatch(updateNanopayShouldShowExtraInfo({ shouldShowExtraInfo: true }));
      dispatch(updateShowErrorCard({ showErrorCard: true }));
    }
    return success;
  }
);

export const handleBankLogin = thunkActionFactory<Pick<Nanopay, 'isBusiness'>>(
  async ({ payload, dispatch, state }) => {
    const {
      config: { ssrtid },
    } = state;
    const { isBusiness } = payload || {};

    dispatch(nanopayActions.nanopayTokenServiceInProgress({ tokenLoading: true }));
    dispatch(setBusiness(isBusiness));

    // Prepare the token service data
    const { auth, config, sale, insight } = state;
    const amountInCents = getAmountInCents(sale);
    const email = getReceipientEmail(sale);

    const tokenPayload = {
      amount: amountInCents,
      email,
      invoiceId: sale.referenceNumber,
      isBusiness,
    };
    // @ts-ignore
    Logger.info('Making nanopay token service call with: ', tokenPayload, ssrtid);

    try {
      // Send the request via token service
      const response = await getToken(auth, config, insight, tokenPayload, ssrtid);

      if (isSuccessResponse(response)) {
        const { id, url } = response;
        Logger.info(`Success retrieving nanopay token id:${id} and url`);
        handleSuccess(dispatch, id, url);
      } else {
        if (response) {
          Logger.error(
            `Error retrieving nanopay token id and url. Error message:${response.message}, code:${response.errorCode}`
          );
        }
        handleError(dispatch);
      }
    } finally {
      dispatch(nanopayActions.nanopayTokenServiceInProgress({ tokenLoading: false }));
    }
  }
);

const handleSuccess = (dispatch: Dispatch<any>, id: string, url: string) => {
  dispatch(nanopayActions.nanopayTokenServiceData({ tokenId: id, nanopayUrl: url }));
  dispatch(modalActions.show({ component: BankTransferCAGuest }));
};

const handleError = (dispatch: Dispatch<any>) =>
  dispatch(nanopayActions.nanopayTokenServiceDataError());
const isSuccessResponse = (response: any) => response && response.id && response.url;
const getAmountInCents = (sale: Sale) =>
  sale.amount && sale.amount > 0 ? Math.round(sale.amount * 100) : 0;
const getReceipientEmail = (sale: Sale) =>
  sale?.contact?.toEmails.length ? sale.contact.toEmails[0] : 'payor-email-address-not-found';
