const { isPaymentRequest, isEstimate, calculateMaxAmount } = require('./utils');
import { PAYMENT_MAP } from 'types/constants';
const { AMOUNT_CONFIG } = PAYMENT_MAP;

const ScsWalletType = {
  BANK: 'BANKACCOUNT',
  CARD: 'CARD',
};

const ScsPaymentType = {
  CREDIT_CARD_WALLET: 'credit card',
  BANK_ACCOUNT_WALLET: 'bank',
};

export const isCreditCardPaymentMethod = (paymentMethodType) =>
  paymentMethodType === 'cc' || paymentMethodType === 'dc' || paymentMethodType === 'dc,cc';
export const isBankAccountPaymentMethod = (paymentMethodType) =>
  paymentMethodType === 'bank' || paymentMethodType === 'eft';
export const isNanopayAccountPaymentMethod = (paymentMethodType) => paymentMethodType === 'nanopay';
export const isApplePayPaymentMethod = (paymentMethodType) => paymentMethodType === 'ap';
export const isPayPalPaymentMethod = (paymentMethodType) => paymentMethodType === 'pp';
export const isNanopayPaymentMethod = (paymentMethodType) => paymentMethodType === 'nanopay';

export const PaymentAmountValidator = {
  getMaxPaymentAmount: (balanceAmount, paymentMethod, allowOverPay, region, featureFlags) => {
    let maxAmount;
    const greaterMaxAmountPaymentMethods = ['bank', 'cc', 'dc', 'dc,cc', 'ap'];
    if (
      region === 'US' &&
      featureFlags &&
      featureFlags['cp-increase-max-amount-limit'] &&
      paymentMethod &&
      greaterMaxAmountPaymentMethods.includes(paymentMethod)
    ) {
      maxAmount = calculateMaxAmount(
        balanceAmount,
        AMOUNT_CONFIG.MULTIPLE_FACTOR,
        AMOUNT_CONFIG.MAX_ALLOWED_AMOUNT_CC_DC_ACH
      );
    } else if (paymentMethod === 'eft') {
      maxAmount = calculateMaxAmount(
        balanceAmount,
        AMOUNT_CONFIG.MULTIPLE_FACTOR,
        AMOUNT_CONFIG.MAX_EFT_ALLOWED_AMOUNT
      );
    } else {
      maxAmount = calculateMaxAmount(
        balanceAmount,
        AMOUNT_CONFIG.MULTIPLE_FACTOR,
        AMOUNT_CONFIG.MAX_ALLOWED_AMOUNT
      );
    }

    //In case this invoice cannot be over-payed then we should return balanceAmount as max amount or less
    if (maxAmount > balanceAmount && !allowOverPay) {
      return balanceAmount;
    }
    return maxAmount;
  },
  getOpenInvoiceMaxPaymentAmount: (balanceAmount, achPaymentEnabled, creditCardPaymentEnabled) => {
    if (achPaymentEnabled) {
      return AMOUNT_CONFIG.MAX_ALLOWED_AMOUNT_CC_DC_ACH;
    } else if (creditCardPaymentEnabled) {
      return AMOUNT_CONFIG.MAX_ALLOWED_AMOUNT;
    }
    return balanceAmount;
  },
  validate: ({
    amount,
    balanceAmount,
    paymentMethod,
    allowOverPay,
    transactionType,
    gratuityValue = 0,
    region,
    featureFlags,
    saleAmount,
    isBulkCheckout,
  }) => {
    if (isPaymentRequest(transactionType)) return true;

    if (!amount || !balanceAmount) {
      return false;
    }
    if (isBulkCheckout) {
      return true;
    }

    if (isEstimate(transactionType)) {
      return amount >= balanceAmount && amount <= saleAmount;
    }

    const max = PaymentAmountValidator.getMaxPaymentAmount(
      balanceAmount,
      paymentMethod,
      allowOverPay,
      region,
      featureFlags
    );
    const greaterMaxAmountPaymentMethods = ['bank', 'cc', 'dc', 'dc,cc', 'ap'];
    const shouldIncreaseMaxAllowed =
      greaterMaxAmountPaymentMethods.includes(paymentMethod) &&
      region === 'US' &&
      featureFlags &&
      featureFlags['cp-increase-max-amount-limit'];
    const maxAllowed = shouldIncreaseMaxAllowed
      ? AMOUNT_CONFIG.MAX_ALLOWED_AMOUNT_CC_DC_ACH
      : AMOUNT_CONFIG.MAX_ALLOWED_AMOUNT;
    const totalAmount = amount + gratuityValue;
    return max >= amount && totalAmount >= AMOUNT_CONFIG.MIN_PAYMENT && totalAmount <= maxAllowed;
  },
  validateGratuity: ({ amount, gratuityAmount }) => {
    return !(
      (amount < 5 && gratuityAmount > 5) ||
      (amount >= 5 && amount < 200 && gratuityAmount > amount) ||
      (amount >= 200 && gratuityAmount > amount * 0.5) ||
      gratuityAmount > 1000
    );
  },
  getCanBeFullyPaid(balanceAmount, enabledPaymentMethods, region, featureFlags = {}) {
    let canBeFullyPaid = false;
    const isBankOrCardEnabled =
      enabledPaymentMethods.includes('cc') || enabledPaymentMethods.includes('bank');
    if (
      region === 'US' &&
      featureFlags?.['cp-increase-max-amount-limit'] &&
      isBankOrCardEnabled &&
      balanceAmount <= AMOUNT_CONFIG.MAX_ALLOWED_AMOUNT_CC_DC_ACH
    ) {
      canBeFullyPaid = true;
    } else if (
      (region !== 'US' || !featureFlags?.['cp-increase-max-amount-limit']) &&
      isBankOrCardEnabled &&
      balanceAmount <= AMOUNT_CONFIG.MAX_ALLOWED_AMOUNT
    ) {
      canBeFullyPaid = true;
    }
    return canBeFullyPaid;
  },
  isPartialPaymentEnabled: (feeAmount, partialPaymentEnabled, transactionType, featureFlags) => {
    if (isEstimate(transactionType)) {
      return !!featureFlags?.['cp-allow-deposit-amount-edit'];
    }
    return feeAmount !== undefined ? false : partialPaymentEnabled;
  },
};

// This function get masked account number and format it according to this pattern ****[LAST_FOUR_DIGITS]
export const formatMaskedAccountNumber = (maskedAccountNumber) => {
  const accountNumberPrefix = maskedAccountNumber.slice(0, maskedAccountNumber.length - 4);
  const accountNumberLastFourDigits = maskedAccountNumber.slice(maskedAccountNumber.length - 4);
  return `${accountNumberPrefix.replace(/./g, '*')}${accountNumberLastFourDigits}`;
};

// This function get masked card number and format it according to this pattern [CARD_TYPE]****[LAST_FOUR_DIGITS]
export const formatMaskedCardNumber = (cardType, maskedCardNumber) => {
  if (cardType === 'american-express') {
    cardType = 'amex';
  }
  const cardTypeConverted = cardType.toUpperCase();
  const cardNumberLastFourDigits = maskedCardNumber.slice(maskedCardNumber.length - 4);
  return `${cardTypeConverted}****${cardNumberLastFourDigits}`;
};

export { ScsWalletType, ScsPaymentType };
