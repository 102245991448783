const HttpStatus = require('http-status-codes');

const INVALID_TICKET_STATUS = 499;

class HttpError extends Error {
  constructor() {
    const [statusCode, errorMessage, ErrorIntuitTid, addedHeaders = {}] = arguments;
    super();
    if (!errorMessage) {
      this.message = HttpStatus.getStatusText(statusCode);
    } else {
      this.message = errorMessage;
      if (statusCode === INVALID_TICKET_STATUS) {
        this.name = 'INVALID_IAM_TICKET';
      }
    }
    this.statusCode = statusCode;
    this.ErrorIntuitTid = ErrorIntuitTid;
    this.headers = {
      ...this.headers,
      ...addedHeaders,
    };
  }
}

class AuthenticationError extends HttpError {
  constructor(...args) {
    super(INVALID_TICKET_STATUS, ...args);
    Error.captureStackTrace(this, AuthenticationError);
  }
}

class InvalidParamError extends Error {
  constructor(...args) {
    super(...args);
    Error.captureStackTrace(this, InvalidParamError);
  }
}

class ConnectionError extends HttpError {
  constructor(...args) {
    super(HttpStatus.REQUEST_TIMEOUT, ...args);
    Error.captureStackTrace(this, ConnectionError);
  }
}

class ForbiddenError extends HttpError {
  constructor(...args) {
    super(HttpStatus.FORBIDDEN, ...args);
    Error.captureStackTrace(this, ForbiddenError);
  }
}

class ApplicationError extends HttpError {
  constructor(...args) {
    super(HttpStatus.INTERNAL_SERVER_ERROR, ...args);
    Error.captureStackTrace(this, ApplicationError);
  }
}

class GatewayTimeoutError extends HttpError {
  constructor(...args) {
    super(HttpStatus.GATEWAY_TIMEOUT, ...args);
    Error.captureStackTrace(this, GatewayTimeoutError);
  }
}

class AuthorizationError extends HttpError {
  constructor(...args) {
    super(HttpStatus.UNAUTHORIZED, ...args);
    Error.captureStackTrace(this, AuthorizationError);
  }
}

/**
 * HttpStatus.BAD_REQUEST / 400
 */
class BadRequestError extends HttpError {
  constructor(...args) {
    super(HttpStatus.BAD_REQUEST, ...args);
    this.path = args[1];
    Error.captureStackTrace(this, BadRequestError);
  }
}

class NotFoundError extends HttpError {
  constructor(...args) {
    super(HttpStatus.NOT_FOUND, ...args);
    Error.captureStackTrace(this, NotFoundError);
  }
}

class GoneError extends HttpError {
  constructor(...args) {
    super(HttpStatus.GONE, ...args);
    this.response = { status: HttpStatus.GONE };
    Error.captureStackTrace(this, GoneError);
  }
}

class PreconditionFailedError extends HttpError {
  constructor(...args) {
    super(HttpStatus.PRECONDITION_FAILED, ...args);
    this.response = { status: HttpStatus.PRECONDITION_FAILED };
    Error.captureStackTrace(this, PreconditionFailedError);
  }
}

class SCSTokenExpiredError extends PreconditionFailedError {
  constructor(...args) {
    super(...args);
    Error.captureStackTrace(this, SCSTokenExpiredError);
  }
}

class TimeoutError extends HttpError {
  constructor(...args) {
    super(HttpStatus.REQUEST_TIMEOUT, ...args);
    Error.captureStackTrace(this, TimeoutError);
  }
}

module.exports = {
  AuthenticationError,
  InvalidParamError,
  BadRequestError,
  AuthorizationError,
  ConnectionError,
  GatewayTimeoutError,
  ForbiddenError,
  ApplicationError,
  NotFoundError,
  TimeoutError,
  GoneError,
  PreconditionFailedError,
  SCSTokenExpiredError,
};
