import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BankTransferCAFlinks from 'components/Core/Nanopay/BankTransfer/BankTransferCAFlinks';
import { modalActions } from 'store/modal/slice';
import { colors } from 'styles/cp';

const BankTransferCAGuest = () => {
  const {
    nanopay: { hasFlinksFlowCompleted },
  } = useSelector((state: any) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasFlinksFlowCompleted) dispatch(modalActions.hide());
  }, [hasFlinksFlowCompleted]);

  return (
    <div className="ca-bank-guest-container">
      <div className="ca-bank-guest-container-box">
        <div className="flink-container">
          <BankTransferCAFlinks />
        </div>
      </div>

      <style jsx>{`
        .ca-bank-guest-container {
          display: flex;
          justify-content: center;
          align-items: center;
          height: calc(100vh - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0));
          margin: 0 -15px;

          .ca-bank-guest-container-box {
            display: flex;
            flex-direction: column;

            width: 100vw;
            height: calc(100vh - env(safe-area-inset-top, 0) - env(safe-area-inset-bottom, 0));
            background-color: ${colors.gray07};
            text-align: center;
            border: 1px solid #d4d7dc;
            box-sizing: border-box;
            box-shadow: 0px 6px 24px rgba(0, 0, 0, 0.2);

            .flink-container {
              background-color: #fff;
              height: 100%;
            }
          }
        }
      `}</style>
    </div>
  );
};

export default BankTransferCAGuest;
