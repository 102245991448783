import { getPaymentMethodsOrder, getCookieByName } from 'shared/clientUtils';
import { TXN_MAP } from 'types/constants';

import getState from 'store/utils/getState';
import { saleSelectors } from 'store/sale/selectors';
import {
  getFeatureEligibility,
  getLinkSource,
  getScreenPrevious,
  persistFrictionlessLoggedIn,
} from './helpers';
import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import { concatPaymentMethodWithSubType } from 'businessLogic/Payment/helpers';
import { initAsyncResolver, promisifyAsyncResolver } from '../shared/asyncResolver';

const splunkReporter = SplunkReporter.getInstance();
const logger = 'reporting/SegmentIO.js';

/**
 * @type {import('./SegmentIO')}
 */
const SegmentIO = {
  /**
   * @param {{transaction_type: string, domain_id: string, payment_request_id: string}} param0
   */
  getTransactionId({ transaction_type, domain_id, payment_request_id }) {
    switch (transaction_type || TXN_MAP.TYPES.INVOICE) {
      case TXN_MAP.TYPES.PAYMENT_REQUEST:
        return { domain_id: null, payment_request_id };
      case TXN_MAP.TYPES.INVOICE:
      case TXN_MAP.TYPES.ESTIMATE:
      default:
        return { domain_id, payment_request_id: null };
    }
  },

  resolveTaxonomy: initAsyncResolver(),
  getInvoiceType({ transactionType, shouldShowSchedulePay, shouldShowAutopay, subType, type }) {
    let invoiceType = transactionType;
    if (type === 'SUBSCRIPTION') {
      invoiceType = 'SUBSCRIPTION';
    } else if (shouldShowSchedulePay || shouldShowAutopay) {
      invoiceType = shouldShowAutopay ? 'AP' : 'SP';
    } else if (subType === TXN_MAP.SUB_TYPES.PAYMENT_LINK_MULTIPLE) {
      invoiceType = TXN_MAP.SUB_TYPES.PAYMENT_LINK_MULTIPLE;
    }
    return invoiceType;
  },

  setIsUserSignedIn(isUserSignedIn) {
    SegmentIO.commonData['is_user_signedIn'] = isUserSignedIn;
  },

  clickViewInvoiceEvent() {
    SegmentIO.transactionEngaged({
      ui_object: 'button',
      ui_object_detail: 'view_invoice_pdf',
      ui_action: 'clicked',
      ui_access_point: 'transaction_flow',
    });
  },

  clickPrivacy() {
    SegmentIO.transactionEngaged({
      ui_object: 'button',
      ui_object_detail: 'privacy_statement',
      ui_action: 'clicked',
      ui_access_point: 'transaction_flow',
    });
  },
  //ACH Reporting
  clickSavePaymentMethod(is_checked) {
    SegmentIO.additionalData.savePM = is_checked;
  },

  gratuityClicked(selected) {
    const values = ['5%', '10%', '15%', 'other', 'none'];
    SegmentIO.transactionEngaged({
      activity_type: 'tips',
      ui_object: 'radio_button',
      ui_action: 'enabled',
      ui_access_point: 'transaction_flow',
      ui_object_detail: values[selected - 1], // align to array index
    });
  },

  gratuityOtherAmount(amount) {
    SegmentIO.transactionEngaged({
      activity_type: 'tips',
      ui_object: 'form_field',
      ui_action: 'typed',
      ui_access_point: 'transaction_flow',
      ui_object_detail: amount,
    });
  },

  async initTaxonomyV2(store) {
    try {
      const { sale, insight, companyInfo, auth, payment = {}, ixp, wallet = {}, config } = store;

      const { txnDate, type, receivable } = sale;

      const { region, currency } = companyInfo;

      const {
        offeringId,
        token,
        isPayable,
        isPayEnabled,
        isFullyPaid,
        isPartiallyPaid,
        companyId,
        domainId,
        payorSegmentation,
      } = insight;

      const { ssrtid, schema } = config;

      checkIfSegmentScriptLoadError();

      const domain_id = domainId ? domainId.substring(domainId.indexOf(':') + 1) : null;

      const { isUserSignedIn, recipientEmail } = auth;

      const { balanceAmount } = payment;

      const { enabledPaymentMethods } = wallet;

      SegmentIO.invoice_type = type ? type.toLowerCase() : 'unknown';

      const invoiceStatus = isFullyPaid
        ? 'fully paid'
        : isPartiallyPaid
        ? 'partial Paid'
        : 'not paid';

      const invoiceDueDate = receivable ? receivable.dueDate : null;

      const payment_methods_order = getPaymentMethodsOrder({
        enabledPaymentMethods,
      })
        .map((pm) => pm.replace(',', '-'))
        .join(',');

      const test_name = ixp
        ? Object.values(ixp)
            .filter((test) => test.treatmentKey !== 'CP_CONFIG_DEFAULT')
            .map((test) => `${test.experimentId}:${test.treatmentKey}`)
            .join(',')
        : '';
      const newTaxonomyData = {
        browser_height: window.innerHeight,
        browser_width: window.innerWidth,
        // eslint-disable-next-line
        platform: navigator?.userAgentData?.platform || navigator?.platform || 'unknown',
        ivid: ssrtid,
        product_name: offeringId,
        feature_eligibility: getFeatureEligibility(store),
        screen_previous: getScreenPrevious(),
        invoice_type: SegmentIO.invoice_type,
        object_detail: SegmentIO.invoice_type,
        company_id: companyId,
        currency: currency,
        domain_id,
        intuit_acstoken: token,
        invoice_amount: balanceAmount,
        invoice_creation_date: txnDate,
        invoice_due_date: invoiceDueDate,
        invoice_status: invoiceStatus,
        is_user_signedin: isUserSignedIn,
        merchant_country: region,
        pay_enabled: isPayEnabled,
        payable: isPayable,
        pseudonym_id:
          schema === 'sbseg_cp_payments_prod'
            ? getCookieByName('qbn.uidp')
            : getCookieByName('qbn.ptc.uidp'),
        recipient_email: recipientEmail,
        offering_id: offeringId,
        payment_methods_order,
        page_experience: isPayable ? 'transaction_view' : 'view_only_access', // TODO
        invoicing_experience: !isPayable
          ? 'nonPayEnabled'
          : saleSelectors.achOnlineConvenienceFeeEnabledSelector(sale)
          ? 'ACHOnlineConvenienceFee'
          : 'payEnabled',
        test_name,
        personalization_details: payorSegmentation,
        link_source: getLinkSource(),
      };

      SegmentIO.commonDataV2 = newTaxonomyData;
      this.resolveTaxonomy.resolve(newTaxonomyData);
    } catch (e) {
      SegmentIO.commonDataV2 = {};
      this.resolveTaxonomy.resolve({});
      splunkReporter.contextual({
        logInfo: { logLevel: 'error', logger },
        event: 'report',
        action: 'initTaxonomyV2',
        activityInfo: {
          status: 'error',
        },
        error: {
          stack: e.stack,
          message: e.message,
        },
      });
    }
  },

  getCommonDataV2() {
    return SegmentIO.commonDataV2;
  },
  getBaseTaxonomyProps() {
    return {
      ...SegmentIO.commonDataV2,
      org: 'sbseg',
      purpose: 'prod',
      scope: 'counterpart_portal',
      scope_area: 'checkout_&_shopping_cart',
      screen: 'cp_checkout',
      object: 'transaction',
      screen_path: 'checkout_&_shopping_cart/cp_checkout',
    };
  },
  async resolveBaseTaxonomyProps() {
    await promisifyAsyncResolver(this.resolveTaxonomy);
    return this.getBaseTaxonomyProps();
  },
  getPaymentProps() {
    const state = getState();
    if (!state.payment) {
      return {};
    }
    const {
      sale,
      payment: {
        isDateScheduled,
        isAutoPayOn,
        gratuityValue = 0,
        amount,
        paymentMethodType,
        paymentMethodSubType,
        country,
        lastSuccessfulPaymentResponse,
      },
      wallet: { userWallets, selectedWalletId } = {},
    } = state;

    const balanceAmount = saleSelectors.balanceSelector(sale);

    const personalization_details = {
      ...(country ? { payor_country: country.a2 } : {}),
      ...SegmentIO.getBaseTaxonomyProps().personalization_details,
    };

    let invoice_status = '';
    if (lastSuccessfulPaymentResponse && lastSuccessfulPaymentResponse.amountPaid) {
      invoice_status =
        lastSuccessfulPaymentResponse.amountPaid >= sale.amount ? 'fully paid' : 'partial Paid';
    }

    return {
      features_enabled: [
        ...(gratuityValue > 0 ? ['tips'] : []),
        ...(isDateScheduled ? ['sp'] : []),
        ...(isAutoPayOn ? ['ap'] : []),
      ],
      ...(invoice_status ? { invoice_status } : {}),
      have_pm_in_wallet:
        userWallets && userWallets.some((wallet) => wallet.id === selectedWalletId),
      payment_method: concatPaymentMethodWithSubType(paymentMethodType, paymentMethodSubType),
      remaining_balance: balanceAmount + gratuityValue - amount,
      schedule_active: isDateScheduled || isAutoPayOn,
      tip_amount: gratuityValue,
      payment_type: SegmentIO.invoice_type,
      personalization_details,
    };
  },

  async transactionViewed(props = {}) {
    await this.resolveBaseTaxonomyProps();

    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      event_full: `sbseg|prod|counterpart_portal|checkout_&_shopping_cart/cp_checkout|transaction:viewed:${SegmentIO.invoice_type}_transaction`,
      object_detail: `${SegmentIO.invoice_type}`,
      action: 'viewed',
      ui_object: 'page',
      ui_object_detail: 'payment_form_viewed',
      ui_action: 'loaded',
      ui_access_point: 'external_link',
      ...props,
    };

    SegmentIO.track({
      event: 'transaction:viewed',
      properties,
    });
  },

  transactionSubmitted(props = {}) {
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      screen_path: 'checkout_&_shopping_cart/cp_checkout',
      event_full: `sbseg|prod|counterpart_portal|checkout_&_shopping_cart/cp_checkout_confirmation_page|transaction:submitted:${SegmentIO.invoice_type}_transaction`,
      action: 'submitted',
      ui_object: 'button',
      ui_object_detail: 'pay_button_clicked',
      ui_action: 'clicked',
      ui_access_point: 'transaction_flow',
      ...SegmentIO.getPaymentProps(),
      ...props,
    };

    SegmentIO.track({
      event: 'transaction:submitted',
      properties,
    });
  },

  transactionSucceeded(props = {}) {
    const state = getState();
    const transactionId =
      state.payment &&
      state.payment.lastSuccessfulPaymentResponse &&
      state.payment.lastSuccessfulPaymentResponse.trackingId;
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      screen_path: 'checkout_&_shopping_cart/cp_checkout_confirmation_page',
      event_full: `sbseg|prod|counterpart_portal|checkout_&_shopping_cart/cp_checkout_confirmation_page|transaction:succeeded:${SegmentIO.invoice_type}_transaction`,
      action: 'succeeded',
      ui_object: 'page',
      ui_object_detail: 'cp_checkout_confirmation_page',
      ui_action: 'loaded',
      ui_access_point: 'transaction_flow',
      ...SegmentIO.getPaymentProps(),
      transaction_id: transactionId,
      ...props,
    };

    SegmentIO.track({
      event: 'transaction:succeeded',
      properties,
    });
  },

  transactionFailed(props = {}) {
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      screen_path: 'checkout_&_shopping_cart/cp_checkout_confirmation_page',
      event_full: `sbseg|prod|counterpart_portal|checkout_&_shopping_cart/cp_checkout_confirmation_page|transaction:failed:${SegmentIO.invoice_type}_transaction`,
      action: 'failed',
      ui_object: 'page',
      ui_object_detail: 'cp_checkout_confirmation_page',
      ui_action: 'loaded',
      ui_access_point: 'transaction_flow',
      ...SegmentIO.getPaymentProps(),
      ...props,
    };

    SegmentIO.track({
      event: 'transaction:failed',
      properties,
    });
  },

  transactionEngaged(props = {}) {
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      activity_type: 'general_flow',
      event_full: `sbseg|prod|counterpart_portal|checkout_&_shopping_cart/cp_checkout|transaction:engaged:${SegmentIO.invoice_type}_transaction`,
      action: 'engaged',
      ...props,
    };

    SegmentIO.track({
      event: 'transaction:engaged',
      properties,
    });
  },

  transactionCancelled(props = {}) {
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      ...props,
      event_full: `sbseg|prod|counterpart_portal|checkout_&_shopping_cart/cp_checkout|transaction:canceled:${SegmentIO.invoice_type}_transaction`,
      action: 'canceled',
    };

    SegmentIO.track({
      event: 'transaction:canceled',
      properties,
    });
  },

  /**
   * This can be used to send other events that are not covered by the above methods, and needed for specific use cases.
   * @param {string} event
   * @param {Object} props
   */
  sendEvent(event, props = {}) {
    SegmentIO.track({
      event,
      properties: {
        ...SegmentIO.getBaseTaxonomyProps(),
        ...props,
      },
    });
  },

  cardFormFieldClicked(fieldName) {
    const fieldNameMap = {
      number: 'card_number',
      expDate: 'exp_date',
      cvc: 'cvv_code',
      name: 'name_on_card',
      zipCode: 'zip_code',
    };

    SegmentIO.transactionEngaged({
      ui_object: 'form_field',
      ui_object_detail: fieldNameMap[fieldName],
    });
  },

  bankFormFieldClicked(fieldName) {
    const fieldNameMap = {
      bankCode: 'routing_number',
      accountNumber: 'account_number',
      accountNumberConfirm: 'confirm_account_number',
      name: 'account_holders_name',
    };

    SegmentIO.transactionEngaged({
      ui_object: 'form_field',
      ui_object_detail: fieldNameMap[fieldName],
    });
  },

  getCountryFieldActivityType() {
    const state = getState();
    if (!state.payment) {
      return {};
    }
    const {
      payment: { paymentMethodType },
    } = state;
    return paymentMethodType === 'cc' ? 'pm_credit' : 'pm_debit';
  },

  countryFieldClicked() {
    SegmentIO.transactionEngaged({
      ui_action: 'clicked',
      ui_object: 'dropdown',
      ui_object_detail: 'payor_country_options',
      ui_access_point: 'transaction_flow',
      activity_type: SegmentIO.getCountryFieldActivityType(),
    });
  },

  countryFieldUpdated(country) {
    SegmentIO.transactionEngaged({
      ui_action: 'clicked',
      ui_object: 'dropdown',
      ui_object_detail: `payor_country:${country}`,
      ui_access_point: 'transaction_flow',
      activity_type: SegmentIO.getCountryFieldActivityType(),
    });
  },

  widgetViewed(props = {}) {
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      event_full: `sbseg|prod|counterpart_portal|checkout_&_shopping_cart/cp_checkout|widget:viewed:${SegmentIO.invoice_type}_transaction`,
      object_detail: `${SegmentIO.invoice_type}`,
      action: 'viewed',
      ui_object: 'page',
      ui_object_detail: 'payment_form_viewed',
      ui_action: 'loaded',
      ui_access_point: 'external_link',
      ...props,
    };

    SegmentIO.track({
      event: 'widget:viewed',
      properties,
    });
  },

  widgetEngaged(props = {}) {
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      event_full: `sbseg|prod|counterpart_portal|checkout_&_shopping_cart/cp_checkout|widget:viewed:${SegmentIO.invoice_type}_transaction`,
      object_detail: `${SegmentIO.invoice_type}`,
      action: 'viewed',
      ui_object: 'page',
      ui_object_detail: 'payment_form_viewed',
      ui_action: 'loaded',
      ui_access_point: 'external_link',
      ...props,
    };

    SegmentIO.track({
      event: 'widget:engaged',
      properties,
    });
  },
  async frictionlessLoginShow() {
    const baseTaxonomy = await this.resolveBaseTaxonomyProps();
    const properties = {
      ...baseTaxonomy,
      object: 'widget',
      object_detail: 'frictionless_login_widget',
      action: 'viewed',
      scope_area: 'checkout_&_shopping_cart',
      screen_path: 'checkout_&_shopping_cart/cp_checkout',
      ui_object: 'modal',
      ui_action: 'viewed',
      ui_access_point: 'transaction_flow',
    };

    SegmentIO.track({
      event: 'widget:viewed',
      properties,
    });
  },
  async frictionlessLoginError() {
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      object: 'workflow',
      object_detail: 'frictionless_login_widget',
      action: 'failed',
      scope_area: 'checkout_&_shopping_cart',
      screen_path: 'checkout_&_shopping_cart/cp_checkout',
      ui_object: 'modal',
      ui_action: 'viewed',
      ui_object_detail: 'identity_error',
      ui_access_point: 'transaction_flow',
    };

    SegmentIO.track({
      event: 'workflow:failed',
      properties,
    });
  },
  frictionlessLoginSuccess(ticket) {
    persistFrictionlessLoggedIn(ticket);
    SegmentIO.commonDataV2.feature_eligibility = getFeatureEligibility(getState());

    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      object: 'workflow',
      object_detail: 'frictionless_login_widget',
      action: 'completed',
      scope_area: 'checkout_&_shopping_cart',
      screen_path: 'checkout_&_shopping_cart/cp_checkout',
      ui_object: 'form',
      ui_action: 'typed',
      ui_object_detail: 'frictionless_login_successful',
      ui_access_point: 'transaction_flow',
    };

    SegmentIO.track({
      event: 'workflow:completed',
      properties,
    });
    if (!ticket) {
      splunkReporter.contextual({
        logInfo: { logLevel: 'error', logger },
        event: 'report',
        action: 'track',
        activityInfo: {
          status: 'error',
        },
        error: {
          message: 'No auth ticket has been passed to frictionless login success',
        },
      });
      return;
    }
  },
  frictionlessLoginSubmit() {
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      object: 'widget',
      object_detail: 'frictionless_login_widget',
      action: 'engaged',
      scope_area: 'checkout_&_shopping_cart',
      screen_path: 'checkout_&_shopping_cart/cp_checkout',
      ui_object: 'button',
      ui_action: 'clicked',
      ui_object_detail: 'sure',
      ui_access_point: 'transaction_flow',
    };

    SegmentIO.track({
      event: 'widget:engaged',
      properties,
    });
  },
  frictionlessLoginCancel(source) {
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      object: 'widget',
      object_detail: 'frictionless_login_widget',
      action: 'engaged',
      scope_area: 'checkout_&_shopping_cart',
      screen_path: 'checkout_&_shopping_cart/cp_checkout',
      ui_object: 'link',
      ui_action: 'clicked',
      ui_object_detail: `continue_as_guest_${source}`,
      ui_access_point: 'transaction_flow',
    };

    SegmentIO.track({
      event: 'widget:engaged',
      properties,
    });
  },
  async frictionlessLoginOtpX(store) {
    await this.initTaxonomyV2(store);
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      object: 'widget',
      object_detail: 'frictionless_login_widget',
      action: 'engaged',
      scope_area: 'checkout_&_shopping_cart',
      screen_path: 'checkout_&_shopping_cart/cp_checkout',
      ui_object: 'button',
      ui_action: 'clicked',
      ui_object_detail: 'pop_up_close',
      ui_access_point: 'transaction_flow',
    };

    SegmentIO.track({
      event: 'widget:engaged',
      properties,
    });
  },
  frictionlessLoginNotYou() {
    const properties = {
      ...SegmentIO.getBaseTaxonomyProps(),
      object: 'widget',
      object_detail: 'frictionless_login_widget',
      action: 'engaged',
      scope_area: 'checkout_&_shopping_cart',
      screen_path: 'checkout_&_shopping_cart/cp_checkout',
      ui_object: 'link',
      ui_action: 'clicked',
      ui_object_detail: 'not_you',
      ui_access_point: 'transaction_flow',
    };

    SegmentIO.track({
      event: 'widget:engaged',
      properties,
    });
  },
  /**
   * Facade for Segment.IO Track
   */
  track({ event, properties }) {
    try {
      typeof window === 'object' &&
        window.analytics &&
        window.analytics.track &&
        window.analytics.track(event, properties, {
          context: { tracking_application_name: properties.tracking_application_name },
        });
    } catch (e) {
      splunkReporter.contextual({
        logInfo: { logLevel: 'error', logger },
        event: 'report',
        action: 'track',
        activityInfo: {
          status: 'error',
        },
        error: {
          stack: e.stack,
          message: e.message,
        },
      });
    }
  },
  openInvoicesHelpers: {
    getTotalInvoiceAmountView: (openInvoices, balanceAmount) => {
      return openInvoices
        ? openInvoices.reduce(
            (initialValue, invoice) => initialValue + invoice.balance,
            balanceAmount
          )
        : balanceAmount;
    },
    getInvoiceDueDate: (openInvoices, invoiceDueDate) => {
      return openInvoices && openInvoices.length > 0
        ? openInvoices.reduce(
            (initialValue, invoice) => [...initialValue, invoice.dueDate],
            [invoiceDueDate]
          )
        : invoiceDueDate;
    },
    getInvoiceAmount: (openInvoices, amount) => {
      return openInvoices && openInvoices.length > 0
        ? openInvoices.reduce(
            (initialValue, invoice) => [...initialValue, invoice.totalAmt],
            [amount]
          )
        : amount;
    },
  },
};

async function checkIfSegmentScriptLoadError() {
  try {
    // sending splunk log to track failings of segmentIO script loading
    if (typeof window === 'object') {
      const errorType = !window.analytics
        ? 'missing script'
        : window.analyticsError
        ? 'analytics error'
        : !window.analytics.track
        ? 'missing track method'
        : false;

      if (errorType) {
        const analyticsError = window.analyticsError || {};
        if (!analyticsError.message) {
          return;
        }
        splunkReporter.contextual({
          logInfo: { logLevel: 'error', logger },
          event: 'report',
          action: 'catchAnalyticsError',
          activityInfo: {
            status: 'error',
          },
          error: {
            message: JSON.stringify(analyticsError),
          },
        });
      }
    }
  } catch (e) {
    return;
  }
}

export default SegmentIO;
