import badWords from './bad-words.json';

import { ContactInfoType } from 'shared/types';

export const emailRegex = /^[A-Za-z0-9._-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
export const usPhoneRegex = /^(?:\+1\s?)?(?:\(\d{3}\)|\d{3})[\s.-]?\d{3}[\s.-]?\d{4}$/;

export const isFreeTextValid = (
  text: string,
  desiredLength = 500,
  desiredMinLength = 3,
  forbiddenSpecialCharsRegex: RegExp = /[$%^&|~=`{}[\];<>/]/g
): boolean => {
  const creditCardPattern = /\b[0-9]{4}(?:[-\s][0-9]{4}){3}\b/;
  const personalIdPattern = /\b\d{9}\b/;
  const ssnPattern = /\b[0-9]{3}-[0-9]{2}-[0-9]{4}\b/;
  const bankAccountPattern = /\b[0-9]{10}\b/;
  const digitCount = (text.match(/\d/g) || []).length;
  const isSquareOfFourExceptFour =
    digitCount !== 0 && digitCount !== 4 && Math.sqrt(digitCount) % 2 === 0;
  const isSquareOfThreeExceptThree =
    digitCount !== 0 && digitCount !== 3 && Math.sqrt(digitCount) % 3 === 0;
  if (
    text.length > desiredLength ||
    text.length < desiredMinLength ||
    creditCardPattern.test(text) ||
    personalIdPattern.test(text) ||
    ssnPattern.test(text) ||
    bankAccountPattern.test(text) ||
    isSquareOfFourExceptFour ||
    isSquareOfThreeExceptThree ||
    !isAllowedCharacters(text, forbiddenSpecialCharsRegex) ||
    !validateProfanityText(text)
  ) {
    return false;
  }
  return true;
};

const phoneRegexUSA =
  /^\d{10}$|^\d{3}-\d{3}-\d{4}$|^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$|^\(\d{3}\)\s?\d{3}-\d{4}$/;

export const isSenderContactInfoValid = (
  contactInfoValue: string,
  contactInfoType: ContactInfoType,
  desiredLength = 50
): boolean => {
  let isEmail, containsMoreThanOneEmail, isPhone, containsMoreThanOnePhone;
  switch (contactInfoType) {
    case 'Email':
      isEmail = emailRegex.test(contactInfoValue);
      containsMoreThanOneEmail = /\S+@\S+@\S+/.test(contactInfoValue);
      return isEmail && !containsMoreThanOneEmail && contactInfoValue.length <= desiredLength;
    case 'Phone number':
      isPhone = phoneRegexUSA.test(contactInfoValue);
      containsMoreThanOnePhone = /\d{10,}.*\d{10,}/.test(contactInfoValue);
      return isPhone && !containsMoreThanOnePhone && contactInfoValue.length <= desiredLength;
    default:
      return false;
  }
};
export const isNameValid = (senderName: string): boolean => {
  const nameRegex = /^[a-zA-Z]+( [a-zA-Z]+)*$/;
  const isName = nameRegex.test(senderName);
  return isName && senderName.length <= 50;
};

export const isFormValid = (
  senderName: string,
  senderContactInfo: string,
  senderContactInfoType: ContactInfoType,
  message: string
): boolean => {
  return (
    isNameValid(senderName) &&
    isSenderContactInfoValid(senderContactInfo, senderContactInfoType) &&
    isFreeTextValid(message || '')
  );
};

export const isAllowedCharacters = (
  originalString: string,
  forbiddenSpecialCharsRegex: RegExp = /[$%^&|~=`{}[\];<>/]/g
): boolean => {
  // Check for special characters or HTML entities
  const htmlTagsRegex = /<\/?[\w\s="/.':;#-/?]+>/gi;
  if (forbiddenSpecialCharsRegex.test(originalString) || htmlTagsRegex.test(originalString)) {
    return false;
  }
  // Check that URLs are sanitized
  const urlRegex = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/i;
  if (originalString.match(urlRegex)) {
    const sanitized = encodeURIComponent(originalString);
    if (sanitized !== originalString) {
      return false;
    }
  }
  return true;
};

export const validateProfanityText = (originalString: string): boolean => {
  const badWordsArray = Object.keys(badWords);
  const hasBadWord = badWordsArray.some((el) =>
    new RegExp(`\\b${el}\\b`, 'i').test(originalString)
  );
  if (hasBadWord) {
    return false;
  }
  return true;
};
