import { LocalStorageUtil } from 'businessLogic/utils/storage';
import HttpClient from 'server/helpers/HttpClient';
import { getUUID } from 'server/helpers/uuidGenerator';
import { getWeakTicket } from 'shared/clientUtils';
import { saleSelectors } from 'store/sale/selectors';
import { RootState } from 'store/store';
import { handlePbbResponse, pbbOrderData } from 'types/BusinessLogic';

const httpClient = HttpClient.getInstance();

export const handlePbbOrder = async (state: RootState) => {
  const { payment, sale, config, auth, insight, companyInfo } = state;
  const { selectedBank, amount } = payment;
  const {
    contact: { displayName },
    type,
    isGpu,
    currencyInfo: { currency },
  } = sale;
  const { region: country } = companyInfo;
  const { domainId, token } = insight;
  const { portal, ssrtid } = config;
  const { ticket, realmId, isUserSignedIn, authToken, syncToken, entityId } = auth;
  const headers = getWeakTicket({
    domainId,
    entityId,
    realmId,
    token,
    ticket,
    isUserSignedIn,
    syncToken,
    authToken,
    ssrtid,
  });
  const payorName = saleSelectors.customerNameSelector(sale);
  const payorEmailAddress = saleSelectors.toEmailsSelector(sale)[0];
  if (amount < 1) {
    throw new Error('cannot pay amount < 1');
  }

  const data: pbbOrderData = {
    payment: {
      method: 'PAYBYBANK',
      amount: amount.toFixed(2),
      currencyCode: currency,
    },
    sale: {
      type,
      isGpu: isGpu ?? false,
    },
    payByBankDetail: {
      issuerBankId: selectedBank?.bankId,
      issuerBankName: selectedBank?.bankName,
      payorName,
      payorEmailAddress,
      country,
    },
    contact: {
      name: displayName,
    },
  };

  headers['Authorization'] = `Bearer ${authToken}`;
  const idempotencyKey = `init_pay_idempotency_${token}`;
  const newIdempotencyValue = getUUID();
  const idempotencyValue = LocalStorageUtil.getOrSetStorage(idempotencyKey, newIdempotencyValue);
  headers['intuit_tid'] = idempotencyValue;
  const handlePbbOrderResponse: handlePbbResponse = await httpClient({
    url: `/${portal}/rest/pbb/create-order`,
    endpoint: `/${portal}/rest/pbb/create-order`,
    method: 'POST',
    data,
    headers,
    timeout: 60000,
  });
  return handlePbbOrderResponse.data;
};

export const invalidatePBBIdempotencyKey = ({
  payment: { paymentStatus, paymentMethodType } = {
    paymentStatus: '',
    paymentMethodType: '',
  },
  insight: { token } = { token: '' },
}) => {
  if (paymentMethodType === 'pbb' && paymentStatus) {
    const idempotencyKey = `init_pay_idempotency_${token}`;
    LocalStorageUtil.removeFromStorage(idempotencyKey);
  }
};
