import { SplunkReporter } from 'reporting/splunk/SplunkReporter';
import { FeatureFlags } from 'types/FeatureFlags';
// Used to log missing riskProfileTokens for a fraction of requests.

const splunkReporter = SplunkReporter.getInstance();
const logger = 'businessLogic/utils/BFS.ts';

export const logIfMissingriskProfileToken = ({
  riskProfileToken,
  featureFlags = {},
  ssrtid,
  token,
  where,
}: {
  riskProfileToken?: string;
  ssrtid: string;
  featureFlags?: FeatureFlags;
  token: string;
  where: string;
}) => {
  if (featureFlags && !riskProfileToken) {
    splunkReporter.contextual({
      event: 'bfs',
      action: 'missingRiskProfileToken',
      logInfo: { logLevel: 'warn', logger },
      sessionId: ssrtid,
      token,
      error: {
        where,
        message: 'missing risk profile token',
      },
    });
  }
};
