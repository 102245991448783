import { PayloadAction } from '@reduxjs/toolkit';

import { isInvoice } from 'shared/utils';
import { errorActions } from 'store/error/slice';
import { insightActions } from 'store/insight/slice';
import { sliceFactory, thunkActionFactory } from 'store/utils';
import { CustomerOpenInvoices, Sale, SaleAcceptStatus, SubscriptionInfo } from 'types/Sale';

const setError = errorActions.set;

// @ts-expect-error
// type mismatch
const initialState = {
  amount: null,
  contacts: null,
  currencyInfo: null,
  entity: null,
  freight: null,
  lines: null,
  paymentEnabledForLocale: null,
  paymentDetailMessage: null,
  receivable: null,
  referenceNumber: null,
  shipping: null, //not sure if can be calculated
  shippingAmount: null, // does not exist on original scs invoices
  tax: null,
  txnDate: null,
  type: null,
  isPartiallyPaid: false,
  riskProfileToken: null,
} as Sale;

const { reducer, actions } = sliceFactory({
  name: 'sale',
  initialState,
  reducers: {
    updateSaleData(
      state,
      action: PayloadAction<{
        balance: Sale['receivable']['balance'];
        isPartiallyPaid: Sale['isPartiallyPaid'];
      }>
    ) {
      const { balance, isPartiallyPaid } = action.payload;
      state.receivable.balance = balance;
      state.isPartiallyPaid = isPartiallyPaid;
    },
    updateEstimateStatus(state, action: PayloadAction<{ status: SaleAcceptStatus }>) {
      if (state.acceptStatusInfo) {
        state.acceptStatusInfo.acceptStatus = action.payload.status;
      }
    },
    updateNewBalance(state, action: PayloadAction<{ newBalance: number }>) {
      const { newBalance } = action.payload;
      state.receivable.balance = newBalance;
    },
    updateCustomerOpenInvoices(state, action: PayloadAction<CustomerOpenInvoices>) {
      state.customerOpenInvoices = action.payload;
    },
    updateSubscriptionInfo(state, action: PayloadAction<SubscriptionInfo['subscriptionInfo']>) {
      if (state.subscriptionInfo) {
        state.subscriptionInfo.subscriptionInfo = action.payload;
      }
    },
  },
});

export const saleReducer = reducer;
export const saleActions = actions;

export const setStatusAccept = thunkActionFactory(async ({ dispatch, businessLogic }) => {
  try {
    await businessLogic.sale.setStatusToAccepted();
    dispatch(
      saleActions.updateEstimateStatus({ status: businessLogic.sale.status as SaleAcceptStatus })
    );
  } catch (e) {
    dispatch(
      setError({ title: 'SET_SALE_STATUS_ERROR_TITLE', body: 'SET_SALE_STATUS_ERROR_BODY' })
    );
  }
});

export const setStatusDeclined = thunkActionFactory(async ({ dispatch, businessLogic }) => {
  try {
    await businessLogic.sale.setStatusToDeclined();
    dispatch(
      saleActions.updateEstimateStatus({ status: businessLogic.sale.status as SaleAcceptStatus })
    );
  } catch (e) {
    dispatch(
      setError({ title: 'SET_SALE_STATUS_ERROR_TITLE', body: 'SET_SALE_STATUS_ERROR_BODY' })
    );
  }
});

export const updateSaleData = thunkActionFactory(async ({ dispatch, businessLogic }) => {
  dispatch(insightActions.updateInProgress());

  try {
    dispatch(saleActions.updateSaleData(await businessLogic.sale.updateSaleData()));
    dispatch(insightActions.updateSuccess());
  } catch (e: any) {
    dispatch(insightActions.updateFail());
  }
});

export const updateNewBalance = thunkActionFactory<{
  amountPaid: number;
}>(({ payload, dispatch, businessLogic }) => {
  const { amountPaid } = payload || {};
  businessLogic.sale.balanceAfterPayment = { amountPaid };
  dispatch(
    saleActions.updateNewBalance({
      newBalance: businessLogic.sale.balance,
    })
  );
});

export const getOpenInvoices = thunkActionFactory(async ({ dispatch, state, businessLogic }) => {
  const {
    auth: { isUserSignedIn },
    insight: { offeringId },
    sale: { type },
  } = state;

  const MD_BREAKPOINT = 720;

  const isScreenBigEnough = window.innerWidth > MD_BREAKPOINT;

  const isInvoiceTxn = isInvoice(type);
  const isQboProduct = offeringId === 'QBO';

  if (!isUserSignedIn || !isInvoiceTxn || !isQboProduct || !isScreenBigEnough) {
    return;
  }

  try {
    const payload = await businessLogic.sale.fetchOpenInvoices();

    if (payload) {
      // @ts-ignore
      dispatch(saleActions.updateCustomerOpenInvoices(payload));
    }
  } catch (e: any) {
    return;
  }
});

export const getUnpaidInvoices = thunkActionFactory(async ({ dispatch, state, businessLogic }) => {
  const {
    sale: { type },
  } = state;
  try {
    const payload = await businessLogic.sale.fetchUnpaidInvoices();
    const isInvoiceTxn = isInvoice(type);

    if (!isInvoiceTxn) return; // TODO: add more conditions

    if (payload) {
      // @ts-ignore
      dispatch(insightActions.updateUnpaidInvoices(payload));
    }
  } catch (e: any) {
    return;
  }
});

export const updateSubscriptionInfo = thunkActionFactory<SubscriptionInfo['subscriptionInfo']>(
  ({ dispatch, payload }) => {
    if (payload) {
      dispatch(saleActions.updateSubscriptionInfo(payload));
    }
  }
);
